import React from 'react';
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

export default ({ user, allowEntry, allowExit }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Box p={2} boxShadow={3}>
        <Grid item container spacing={2}>
          <Grid item xs={4} sm={2}>
            <img src={user.profile_picture || '/profile-placeholder.png'} style={{ maxWidth: '100%' }} />
          </Grid>
          <Grid item xs={8} sm={10}>
            <Typography variant="h6" style={{ textTransform: 'capitalize' }}>
              {user.name} {user.surname}
            </Typography>
            <Typography variant="body2">{user.company}</Typography>
            <Typography>
              {user.email}
            </Typography>
            <Typography>
              {user.cellphone}
            </Typography>
            <hr />
            <Typography>
              {user.Building.name}, {user.Building.address}
            </Typography>
            <hr />
            <Typography>
              <b>{t('Access code')}:</b> {user.facility_code} - {user.key}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box my={2} textAlign="right">
              <Button
                type="button"
                onClick={() => allowEntry({ user_id: user.id, building_id: user.Building.id })}
                variant="contained"
                size="small"
                color="secondary"
              >
                {t('Authorize entry')}
              </Button>
              &nbsp;
              <Button
                type="button"
                onClick={() => allowExit({ user_id: user.id, building_id: user.Building.id })}
                variant="contained"
                size="small"
                style={{ backgroundColor: '#8c0404', color: 'white' }}
              >
                {t('Authorize exit')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
