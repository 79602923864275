import * as React from 'react';
import { connect } from 'react-redux';
import { loggedIn } from '../../actions/auth';
import LoginForm from '../../components/Login';
import { setBuildings } from '../../actions/buildings';
import * as AuthApi from '../../api/auth';
import * as GuardsApi from '../../api/guards';

const REACT_APP_SITE_URL = process.env.REACT_APP_SITE_URL;

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    const { data } = await AuthApi.login({
      ...body,
      device_id: '8532',
    });

    this.props.loggedIn(data);

    if (data.user.should_change_password) {
      this.props.history.push('/app/change-password?redirected=1');
    }

    const { data: buildings } = await GuardsApi.buildings();

    if (!buildings.length) {
      return window.location.replace(REACT_APP_SITE_URL);
    }

    this.props.setBuildings(buildings);
  }

  render() {
    return (
      <LoginForm
        onSubmit={this.onSubmit}
      />
    )
  }
}

const mapDispatchToProps = dispatch => ({
  loggedIn: (data) => dispatch(loggedIn(data)),
  setBuildings: (b) => dispatch(setBuildings(b)),
});

export default connect(null, mapDispatchToProps)(LoginContainer);
