import * as React from 'react';
import { connect } from 'react-redux';
import Dashboard from '../../components/Dashboard';
import { setSelectedBuilding } from '../../actions/buildings';

class DashboardContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  changeBuilding = (id) => {
    this.props.setSelectedBuilding(id);
  }

  onQrRead = (value) => {
    if (value) {
      try {
        navigator.vibrate([100]);
      } catch (err) {
        console.log(err);
      }
      this.props.history.push(`/app/search?type=code&value=${value}`);
    }
  }

  render() {
    const currentBuilding = this.props.buildings.find((b) => b.id === this.props.selected);

    return (
      <Dashboard
        loading={this.state.loading}
        buildings={this.props.buildings}
        currentBuilding={currentBuilding}
        changeBuilding={this.changeBuilding}
        onQrRead={this.onQrRead}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  buildings: state.buildings.records,
  selected: state.buildings.selected,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedBuilding: (b) => dispatch(setSelectedBuilding(b)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
