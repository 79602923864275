import React from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Checkbox } from '../Shared/BsInput';
import { TosSchema } from '../../validation-schemas';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  isValid,
  t,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <div>
        <Checkbox
          name="tos"
          value={values.tos}
          onChange={handleChange}
          errors={errors}
        />
        <>
          <a href="http://podesubir.com/pt-tncs" target="_blank" rel="noopener noreferrer">{t('Terms and Conditions')}</a>
        </>
      </div>
      <div>
        <Checkbox
          name="privacy"
          value={values.privacy}
          onChange={handleChange}
          errors={errors}
        />
        <>
          <a href="http://podesubir.com/privacy-pt" target="_blank" rel="noopener noreferrer">{t('Privacy policy')}</a>
        </>
      </div>
      <hr />
      <Button
        className="mt-2"
        variant="outlined"
        color="default"
        type="submit"
        disabled={isSubmitting || !isValid}
        fullWidth
      >
        {t('Accept')}
      </Button>
      <hr />
    </form>
  );
};

export default function Modal({ onSubmit }) {
  const { t } = useTranslation();

  return (
    <div>
      <Dialog open={true} aria-labelledby="form-dialog-title">
        <DialogTitle>
          {t('Attention')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('There was changes in our Terms of service and Privacy Policy. Please review them and make sure you accept them before you continue using our application.')}
          </DialogContentText>
          <Formik
            initialValues={{
              tos: false,
              privacy: false,
            }}
            validationSchema={TosSchema}
            enableReinitialize={true}
            onSubmit={
              async (values, { setSubmitting, setErrors }) => {
                try {
                  await onSubmit(values);
                } catch (err) {
                  if (err.response && err.response.data.errors) {
                    setErrors(err.response.data.errors);
                  }

                  setSubmitting(false);
                }
              }
            }
            render={p => <RenderForm {...p} {...{ t }}/>}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}