import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1C4454',
    },
    secondary: {
      main: '#019F01',
      contrastText: '#fff',
    }
  },
  typography: {
    fontFamily: 'Source Sans Pro'
  },
  overrides: {
    MuiButton: {
      root: {
        // borderRadius: 0,
      },
      fullWidth: {
        textTransform: 'lowercase',
      },
      outlined: {
        borderWidth: '2px',
      },
    },
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: '#3B3B3B',
        textTransform: 'lowercase',
      },
    },
    MuiTypography: {
      h6: {
        textTransform: 'lowercase',
      }
    }
  }
});

export default theme;
