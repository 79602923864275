import {
  SET_BUILDINGS,
  SET_SELECTED_BUILDING,
} from '../constants';

export function setSelectedBuilding(id) {
  return {
    payload: id,
    type: SET_SELECTED_BUILDING,
  };
}

export function setBuildings(buildings) {
  return {
    payload: buildings,
    type: SET_BUILDINGS,
  };
}
