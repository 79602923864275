import * as React from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import RenderForm from '../Dashboard/search-form';
import EmployeeListItem from './employee-list-item';

const styles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2, 2),
    marginBottom: theme.spacing(1),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
}));

export default ({
  initialValues, onSubmit, results,
  allowEntry,
  allowExit,
}) => {
  const { t } = useTranslation();
  const classes = styles();

  return (
    <>
      <Typography gutterBottom>
        <Link to="/app/employees" className={classes.link}>
          <i className="fa fa-share fa-flip-horizontal"></i> {t('Go back')}
        </Link>
      </Typography>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12}>
          <Paper elavation={3} square className={classes.paper}>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validate={(values) => {
                const errors = {};

                if (!values.type) {
                  errors.type = 'Required';
                }

                if (!values.value) {
                  errors.value = 'Required';
                }

                return errors;
              }}
              onSubmit={
                async (values, { setSubmitting, setErrors }) => {
                  try {
                    await onSubmit({ ...values });
                    setSubmitting(false);
                  } catch (err) {
                    if (err.response && err.response.data.errors) {
                      setErrors(err.response.data.errors);
                    }

                    setSubmitting(false);
                  }
                }
              }
              render={RenderForm}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {
            results && !results.length &&
            <Alert elevation={6} variant="filled" severity="info" icon={false}>
              {t('No employees could be found')}
            </Alert>
          }
        </Grid>
        <Grid item xs={12}>
          {
            results && !!results.length && (
              <>
                {
                  results.map((result, i) => {
                    return (
                      <Grid item container spacing={2} justify="center" key={i} >
                        <Grid item container xs={12}>
                          <EmployeeListItem user={result} allowEntry={allowEntry} allowExit={allowExit} />
                        </Grid>
                      </Grid>
                    )
                  })
                }
              </>
            )
          }
        </Grid>
      </Grid>
    </>
  );
}
