import { CardActionArea } from '@material-ui/core';
import {
  SET_BUILDINGS,
  SET_SELECTED_BUILDING,
} from '../constants';

const defaultState = {
  records: [],
  selected: null,
};

export default function(state = defaultState, action) {
  switch(action.type) {
    case SET_BUILDINGS: {
      const records = action.payload;
      const stored = localStorage.getItem('selected-building');
      let selected = records[0].id;

      if (stored) {
        const found = records.find((b) => b.id === parseInt(stored, 10));

        if (found) {
          selected = found.id;
        }
      }

      return {
        ...state,
        records,
        selected,
      };
    }
    case SET_SELECTED_BUILDING: {
      localStorage.setItem('selected-building', action.payload);

      return {
        ...state,
        selected: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
