import * as React from 'react';
import Mask from 'react-text-mask';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  Select as MaterialSelect,
  Checkbox as MaterialCheckbox,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  InputAdornment,
  IconButton,
} from '@material-ui/core';

const MASKS = {
  'cpf-input': [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  'rg-input': [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/],
  'rne-input': [/[a-zA-Z]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /[a-zA-Z]/],
};

const getHelpOrError = (help, errors, name, t) => {
  if (errors && errors[name]) {
    return t(errors[name]);
  }

  if (help) {
    return help;
  }

  return null;
};

function TextMaskCustom(props) {
  const { inputRef, mask, ...other } = props;

  return (
    <Mask
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={MASKS[other.id]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
}

export const MaskedInput = (props) => {
  const { type, label, name, help, errors, value, ...rest } = props;
  const { t } = useTranslation();

  return (
    <TextField
      label={label || ''}
      margin="dense"
      type={type || 'text'}
      value={value || ''}
      fullWidth={true}
      error={errors && !!errors[name]}
      InputProps={{
        inputComponent: TextMaskCustom
      }}
      helperText={getHelpOrError(help, errors, name, t)}
      name={name}
      {...rest}
    />
  );
}

export const Password = (props) => {
  const [showPwd, setShowPwd] = React.useState(false);
  const { type, label, name, errors, value, help, ...rest } = props;
  const { t } = useTranslation();

  const changeShowPwd = () => {
    setShowPwd(!showPwd);
  };

  return (
    <TextField
      label={label || ''}
      margin="dense"
      name={name}
      type={showPwd ? 'text' : 'password'}
      value={value || ''}
      fullWidth={true}
      helperText={getHelpOrError(help, errors, name, t)}
      error={errors && !!errors[name]}
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={changeShowPwd}
            >
              {showPwd ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
            </IconButton>
          </InputAdornment>

      }}
      {...rest}
    />
  );
}

export const Input = (props) => {
  const { type, label, name, errors, value, help, ...rest } = props;
  const { t } = useTranslation();

  return (
    <TextField
      label={label || ''}
      margin="dense"
      name={name}
      type={type || 'text'}
      value={value || ''}
      fullWidth={true}
      helperText={getHelpOrError(help, errors, name, t)}
      error={errors && !!errors[name]}
      {...rest}
    />
  );
}

export const Select = (props) => {
  const { type, label, name, errors, value, options, help, ...rest } = props;
  const { t } = useTranslation();

  return (
    <FormControl fullWidth margin="none">
      {
        !!label &&
        <InputLabel>{label || ''}</InputLabel>
      }
      <MaterialSelect
        margin="dense"
        name={name}
        value={value || ''}
        fullWidth={true}
        error={errors && !!errors[name]}
        {...rest}
      >
        {
          options.map((o, i) => <MenuItem key={i} value={o.value}>{o.label}</MenuItem>)
        }
      </MaterialSelect>
      <FormHelperText error={errors && !!errors[name]}>
        {getHelpOrError(help, errors, name, t)}
      </FormHelperText>
    </FormControl>
  );
}

export const Textarea = (props) => {
  const { label, name, value, help, errors, ...rest } = props;
  const { t } = useTranslation();

  return (
    <TextField
      label={label || ''}
      margin="dense"
      name={name}
      type={'text'}
      value={value || ''}
      fullWidth={true}
      helperText={getHelpOrError(help, errors, name, t)}
      error={errors && !!errors[name]}
      {...rest}
      multiline={true}
      rows={3}
    />
  );
}

export const Checkbox = (props) => {
  const { label, name, value, ...rest } = props;

  return (
    <MaterialCheckbox
      checked={value}
      value={value}
      name={name}
      {...rest}
    />
  );
}
