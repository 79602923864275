import axios from 'axios';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import { logout } from '../actions/auth';
import { req, res } from '../actions/ui';
import store from '../store';

const BASEURL = process.env.REACT_APP_API_URL;
const REACT_APP_SITE_URL = process.env.REACT_APP_SITE_URL;

export function getUTCTime() {
  return axios.get(`${BASEURL}/tell-me-the-time-oh-magical-endpoint-because-the-totp-will-not-work-when-people-changes-their-local-time-for-whatever-reason-so-give-me-the-right-time-please-if-you-will-thank-you`);
}


export function catchError(error) {
  const { status, data } = error.response;

  switch (status) {
    case 401:
      store.dispatch(logout());
      window.location.href = '/login';
      break;
    case 403:
      return window.location.replace(REACT_APP_SITE_URL);
    default: {
      toast.error(data.message || data.error);
    }
  }

  store.dispatch(res());

  throw error;
}

function removeEmptyQs(params) {
  return Object.keys(params).reduce((acc, key) => {
    if (params[key] || params[key] === 0) {
      acc[key] = params[key];
    }

    return acc;
  }, {});
}

export default function request({ method, params, body, path }) {
  const options = {
    method,
    url: `${BASEURL}${path}`,
    headers: {},
  };

  if (localStorage.getItem('token')) {
    options.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }

  if (params) {
    if (params.where) {
      params.where = removeEmptyQs(params.where);
    } else {
      params = {
        ..._.pick(params, ['attributes', 'limit', 'offset', 'order']),
        ...removeEmptyQs(_.omit(params, ['attributes', 'limit', 'offset', 'order'])),
      };
    }

    options.params = params;
  }

  if (body) {
    options.data = body;
  }

  store.dispatch(req());

  return axios(options)
    .then((response) => {
      store.dispatch(res());

      return response;
    }).catch(catchError);
}
