import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
} from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: '#333',
    display: 'flex',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#f5f1ee',
    minHeight: '100vh',

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(3),
    },
  },
}));

export default (props) => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const [langMenuAnchorEl, setLangAnchorEl] = React.useState(null);
  const langOpen = Boolean(langMenuAnchorEl);

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  const handleMenu = (setter) => (event) => {
    setter(event.currentTarget);
  }

  const handleClose = (setter) => () => {
    setter(null);
  }

  return (
    <div className={classes.root}>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar)}
      >
        <Toolbar>
          <div style={{ flexGrow: 1 }}>
            <img src="/logo.png" alt="logo" style={{ maxWidth: '100px' }} />
          </div>
          <div style={{ flexGrow: 1, textAlign: 'right' }}>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu(setLangAnchorEl)}
              color="inherit"
              style={{ fontSize: '1.2rem' }}
            >
              <i className="fa fa-language"></i>&nbsp;{i18n.language.substr(0, 2).toUpperCase()}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={langMenuAnchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={langOpen}
              onClose={handleClose(setLangAnchorEl)}
            >
              <MenuItem onClick={handleClose(setLangAnchorEl)}>
                <a
                  href=""
                  className={classes.menuLink}
                  onClick={() => changeLanguage('es')}
                >
                  Español
                </a>
              </MenuItem>
              <MenuItem onClick={handleClose(setLangAnchorEl)}>
                <a
                  href=""
                  className={classes.menuLink}
                  onClick={() => changeLanguage('en')}
                >
                  English
                </a>
              </MenuItem>
              <MenuItem onClick={handleClose(setLangAnchorEl)}>
                <a
                  href=""
                  className={classes.menuLink}
                  onClick={() => changeLanguage('pt')}
                >
                  Português
                </a>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <main
        className={clsx(classes.content)}
      >
        {props.children}
      </main>
    </div>
  );
}