import { LOGIN_SUCCESS, LOGOUT } from '../constants';

export function loggedIn(data) {
  return {
    data,
    type: LOGIN_SUCCESS,
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}
