import * as React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { loggedIn, logout } from '../../actions/auth';
import { setBuildings } from '../../actions/buildings';
import * as AuthApi from '../../api/auth';
import * as GuardsApi from '../../api/guards';
import 'font-awesome/css/font-awesome.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../assets/css/styles.css';

import Navbar from './navbar';
import SimpleNavbar from './simple-navbar';
import Routes from './routes';
import Loading from '../../components/Shared/Loading';
import TosModal from '../Profile/tos';
import theme from '../../theme';

const REACT_APP_SITE_URL = process.env.REACT_APP_SITE_URL;

const getParameterByName = (name) => {
  name = name.replace(/[\[\]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(window.location.href);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const styles = createStyles(theme => ({
  container: {
    marginTop: '4%',
    marginBottom: '4%',

    [theme.breakpoints.down('md')]: {
      marginTop: '8%',
    },

    [theme.breakpoints.down('md')]: {
      marginTop: '10%',
    },

    [theme.breakpoints.down('xs')]: {
      marginTop: '15%',
    },
  }
}));

const Wrapper = ({ loggedIn, logout, user, children }) => {
  if (loggedIn) {
    return (
      <Navbar
        logout={logout}
        user={user}
      >
        {children}
      </Navbar>
    )
  }

  return (
    <SimpleNavbar>{children}</SimpleNavbar>
  );
};

class AppContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: false,
      loading: true,
    };
    this.logout = this.logout.bind(this);
  }

  render() {
    const { isLoggedIn, user, classes } = this.props;
    const loggedIn = isLoggedIn && !!user;
    const { loading } = this.state;
    const shouldShowTosModal = user && (!user.tos || !user.privacy);

    if (loading) {
      return null;
    }

    return (
      <ThemeProvider theme={theme}>
        <Wrapper loggedIn={loggedIn} logout={this.logout} user={user}>
          <div className={classes.container}>
            {
              shouldShowTosModal &&
              <TosModal />
            }
            <Routes
              loggedIn={loggedIn}
            />
          </div>
        </Wrapper>

        <Loading visible={this.props.loading} />
        <ToastContainer position="bottom-left"/>
      </ThemeProvider>
    );
  }

  async componentDidMount() {
    const token = getParameterByName('token');
    if (token) {
      localStorage.setItem('token', token);

      this.props.history.push(this.props.history.pathname);
    }

    if (localStorage.getItem('token')) {
      const { data } = await AuthApi.me();

      this.props.loggedIn({
        token: localStorage.getItem('token'),
        user: data,
      });

      this.setState({ loading: false });

      if (data.should_change_password) {
        this.props.history.push(`${REACT_APP_SITE_URL}/app/change-password?redirected=1`);
      }

      const { data: buildings } = await GuardsApi.buildings();

      if (!buildings.length) {
        return window.location.replace(REACT_APP_SITE_URL);
      }

      this.props.setBuildings(buildings);
    } else {
      this.setState({ loading: false });
    }
  }

  logout() {
    this.props.logout();
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.auth.loggedIn,
  user: state.auth.user,
  loading: state.ui.loading > 0,
});

const mapDispatchToProps = dispatch => ({
  loggedIn: (data) => dispatch(loggedIn(data)),
  logout: () => dispatch(logout()),
  setBuildings: (b) => dispatch(setBuildings(b)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(AppContainer))));
