export default {
  'Email': 'email',
  'Password': 'contraseña',
  'Login': 'acceder',
  'You don\'t have an account?': '¿no tienes cuenta?',
  'Register here': 'abrir nueva cuenta',
  'Role': 'Rol',
  'Name': 'nombre',
  'Save': 'guardar',
  'Surname': 'apellido',
  'Confirm password': 'confirmar contraseña',
  'Register': 'registro',
  'Already have an account?': 'ya tengo una cuenta',
  'sign in': 'haz login',
  'My profile': 'mi perfíl',
  'Logout': 'salir',
  'Change password': 'cambiar contraseña',
  'Your profile picture was updated': 'Tú foto de perfíl fue actualizada',
  'Your profile information was updated': 'Tú información de perfil fue actualizada',
  'New password': 'Nueva contraseña',
  'Confirm new password': 'Confirmar nueva contraseña',
  'Your password has been changed': 'Tú contraseña fue actualizada',
  'Cancel': 'Cancelar',
  'Update information': 'Actualizar información',
  'Upload new picture': 'Cargar nueva imágen',
  'Pending invitations for today': 'Invitaciones pendientes para el día de hoy',
  'There are no pending invitations for today': 'No hay invitaciones pendientes',
  'My buildings': 'mis edificios',
  'My invitations': 'mis invitaciones',
  'No buildings were found': 'No se encontraron edificios',
  'Home': 'Inicio',
  'Building detail': 'Detalle de edificio',
  'Address': 'Domicilio',
  'Manager': 'Encargado',
  'My user roles in this building': 'Mis roles de usuario en este edificio',
  'BUILDING_MANAGER': 'Encargado de edificio',
  'BUILDING_MANAGER.description': 'Permisos para cambiar la informacion, compañias y usuarios de un edificio, acceder al edificio y crear invitaciones',
  'COMPANY_MANAGER': 'Encargado de empresa',
  'COMPANY_MANAGER.description': 'Permisos para cambiar la informacion y usuarios de la empresa, acceder al edificio y crear invitaciones',
  'EMPLOYEE': 'Staff de edificio',
  'EMPLOYEE.description': 'Permisos para acceder al edificio',
  'HOST_EMPLOYEE': 'Staff de edificio con permisos para invitar',
  'HOST_EMPLOYEE.description': 'Permisos para acceder al edificio y crear invitaciones',
  'Edit': 'Editar',
  'Manage companies': 'Administrar compañias',
  'No records found': 'No se encontraron registros',
  'My roles': 'Mis roles',
  'Manage users': 'Administrar usuarios',
  'Detail': 'Detalle',
  'The building information has been updated': 'La información del edificio fue actualizada',
  'Building\'s users': 'Usuarios del edificio',
  'Go to detail': 'Ir al detalle',
  'Add user to building': 'Agregar usuario a edificio',
  'Add user': 'Agregar usuario',
  'Remove user': 'Remover usuario',
  'Remove': 'Remover',
  'User email': 'Email del usuario',
  'The user has been added to the building': 'El usuario fue agregado al edificio',
  'The user has been removed from the building': 'El usuario fue removido del edificio',
  'Remove user from building?': '¿Remover usuario del edificio?',
  'Remove user from company?': '¿Remover usuario de la compañia?',
  'Yes': 'Si',
  'No': 'No',
  'My companies': 'mis compañias',
  'Companies': 'Compañias',
  'Edit company': 'Editar compañia',
  'Create company': 'Crear compañia',
  'Manager email': 'Email del manager',
  'The company has been added to the building': 'La compañia fue agregada al edificio',
  'The company has been updated': 'La compañia fue actualizada',
  'The user has been added to the company': 'El usuario fue agregado a la compañia',
  'The user has been removed from the company': 'El usuario fue removido de la compañia',
  'Add user to company': 'Agregar usuario a la compañia',
  'Company\'s users': 'Usuarios de la compañia',
  'Back to the building\'s companies': 'Volver a las compañias del edificio',
  'My user roles in this company': 'Mis roles de usuario en esta compañia',
  'Company detail': 'Detalle de compañia',
  'New invitation': 'Nueva invitacion',
  'The invitation has been sent': 'La invitacion fue enviada',
  'Building': 'edificio',
  'Host': 'Anfitrion',
  'You do not have invite permissions in any building': 'No tienes permisos para invitar en ningún edificio',
  'Guest\'s email': 'Email del invitado',
  'Guest': 'Invitado',
  'Invitation date': 'Horario',
  'As myself': 'Yo',
  'Invitation subject/reason': 'Asunto',
  'Invitation duration': 'Duracion de la invitacion',
  'Modify meeting': 'Actualizar',
  '1 hour': '1 hora',
  '2 hours': '2 horas',
  '3 hours': '3 horas',
  '5 hours': '5 horas',
  '12 hours': '12 horas',
  'Notes': 'Comentarios',
  'Allow access to the building for': 'Duracion',
  'Create invitation': 'Crear invitacion',
  'Invitation detail': 'Detalle de invitacion',
  'Date': 'Fecha',
  'hours': 'horas',
  'Access allowed for': 'Permitir acceso por',
  'You are hosting': 'tu invitaste',
  'Confirmed': 'Confirmada',
  'Waiting confirmation': 'Esperando confirmación',
  'Rejected': 'Rechazada',
  'Cancelled': 'Cancelada',
  'Status': 'Estado',
  'Subject/Reason': 'Asunto/Motivo',
  'Sent to': 'Enviada a',
  'Sent date': 'Fecha de enviada',
  'I forgot my password': 'Se me olvidó mi contraseña',
  'Password recovery': 'Recuperar contraseña',
  'Input your email and we will send you a link to restore your password': 'Ingrese su email y le enviaremos un link para recuperar la contraseña',
  'Back': 'Volver',
  'An email was sent to your address with instructions on how to restore your password': 'Se enviaron instrucciones sobre como recuperar su contraseña',
  'Request password recovery': 'Solicitar recuperar contraseña',
  'Your password as been restored. You can now access with your new password': 'Su contraseña fue restrablecida. Ahora puede iniciar sesión con su nueva contraseña',
  'Your email has been verified': 'Su correo fue verificado',
  'The email could not be verified': 'El correo no pudo ser verificado',
  'Verify email': 'Verificar correo',
  'PAST_DUE': 'Vencida',
  'CANCELLED': 'Cancelada',
  'REJECTED': 'Rechazado',
  'CONFIRMED': 'Aceptado',
  'PENDING': 'No confirmado',
  'Confirm': 'Confirmar',
  'Reject': 'Rechazar',
  'The assistance to the invitation has been confirmed': 'La asistencia a la invitacion fue confirmada',
  'Confirm assistance?': '¿Confirmar asistencia?',
  'The invitation has been cancelled': 'La invitacion fue cancelada',
  'Cancel invitation?': 'Al rechazar esta invitación se le avisará al anfitrión. Estás seguro de que quieres rechazarla?',
  'The invitation has been rejected': 'La invitacion fue rechazada',
  'Reject invitation?': 'Al rechazar esta invitación se le avisará al anfitrión. Estás seguro de que quieres rechazarla?',
  'Required': 'Requerido',
  'Invalid email': 'Correo invalido',
  'Password confirm does not match': 'Las contraseñas no coinciden',
  'Select a building': 'Seleccionar edificio',
  'Cellphone': 'Móvil',
  'Birthdate': 'Fecha de nacimiento',
  'Habilitaçao': 'CNH',
  'RNE number': 'RNE',
  'ID Type': 'documento de identidad',
  'Invalid ID number': 'Numero de identificacion invalido',
  'RG': 'RG',
  'RNE': 'RNE',
  'Passport': 'Pasaporte',
  'RG number': 'RG',
  'CPF number': 'CPF',
  'Invalid RG number': 'RG invalido',
  'Invalid CPF number': 'CPF invalido',
  'Secretary': 'Secretaria',
  'My secretaries': 'definir delegados',
  'New secretary': 'nuevo delegado',
  'The user has been added as a secretary': 'El usuario fue agregado como secretaria',
  'No results': 'Sin resultados',
  'Search and select an user': 'Buscar y seleccionar usuario',
  'The user has been removed from your secretaries': 'El usuario fue removido de sus secretarias',
  'Remove user from your secretaries?': '¿Remover usuario de sus secretarias?',
  '1. Select the building': '1. Seleccionar edificio',
  '2. Search a user and select from the list': '2. Buscar y seleccionar usuario del listado',
  'Send invitation as yourself or acting as a secretary for another user': 'Enviar invitacion a tu nombre o actuando como secretaria de otro usuario',
  'Building not found': 'Edificio no encontrado',
  'Invalid token': 'Token invalido',
  'Forbidden': 'Prohibido',
  'There\'s no user registered with that email': 'No hay usuarios registrados con ese correo',
  'Email or password incorrect': 'Email/contraseña incorrecto',
  'The building manager can not be removed': 'El encargado de edificio no puede ser removido',
  'Company not found': 'Compañia no encontrada',
  'The company manager can not be removed': 'El encargado de la compañia no puede ser removido',
  'You cannot invite in this building': 'No puedes invitar en este edificio',
  'The user cannot invite in this building': 'El usuario no puede invitar en este edificio',
  'Missing file': 'Sin archivo',
  'You can not cancel a past invitation': 'No puedes cancelar una invitacion pasada',
  'The invitation can not be cancelled': 'La invitacion no puede ser cancelada',
  'You can not reject your own invitation': 'No puedes rechazar tu propia invitacion',
  'You can not reject a past invitation': 'No puedes rechazar una invitacion pasada',
  'The invitation can not be rejected': 'La invitacion no pudo ser rechazada',
  'You can not confirm your own invitation': 'No puedes confirmar tu propia invitacion',
  'You can not confirm a past invitation': 'No puedes confirmar una invitacion pasada',
  'The invitation can not be confirmed': 'La invitacion no puede ser confirmada',
  'Invitation not found': 'Invitacion no encontrada',
  'Can\'t create invitations on behalf user': 'No puede crear invitaciones en nombre del usuario',
  'The user can\'t create invitations in this building': 'El usuario no puede crear invitaciones en este edificio',
  'You are not allowed to perform this action': 'No esta autorizado a realizar esta accion',
  'Multiple emails separated by comma': 'Varios emails separados por coma',
  'Sent invitations': 'invitaciones enviadas',
  'Received invitations': 'invitaciones',
  'Account configuration': 'configuraciones',
  'My information': 'mi información',
  'Account': 'Configuraciones de la cuenta',
  'My vehicle': 'mi vehiculo',
  'Your vehicle information was updated': 'la información de vehiculo fue actualizada',
  'Make': 'marca',
  'Model': 'modelo',
  'Color': 'color',
  'License plate': 'Patente',
  'The documents were uploaded': 'Los documentos fueron actualizados',
  'My documents': 'Mis documentos',
  'ID Front': 'actualizar foto del frente de mi documento de identidad',
  'ID Back': 'actualizar foto del lado de atrás de mi documento de identidad',
  'Email or cellphone number': 'email o número de celular',
  'If using a cellphone number, it must be valid and include both country and area code. Ex (5511943210091)': 'En caso de usar un número de celular, debe ser valido e incluir el codigo de pais y de area. Ej (5511943210091)',
  'Verify cellphone': 'Verificar número de celular',
  'Your cellphone has been verified': 'Su numero de celular fue verificado',
  'The cellphone could not be verified': 'El numero de celular no pudo ser verificado',
  'A message was sent to your number with a verification code': 'Se envió un mensaje a tú número con un codigo para la verificación',
  'Skip': 'Omitir',
  'Cellphone number': 'Número de móvil',
  'Only numbers. Including country and area code': 'Solo números. Incluyendo codigo de país y de área',
  'Verify cellphone number': 'Verificar número de celular',
  'Your cellphone is already verified': 'Sú número de celular ya está verificado',
  'Request verification code': 'Solicitar código de verificación',
  'Verification code': 'Código de verificación',
  'Verify': 'Verificar',
  'Invalid number': 'Número invalido',
  'Invalid email or cellphone': 'email o número de celular invalido',
  'Change email': 'Cambiar email',
  'Change phone': 'Cambiar número de telefono',
  'We have sent an email to your new email address. The change will be efective once you verify this new email': 'Enviamos un correo a su nueva dirección. El cambio se hará efectivo una vez se haya verificado esta nueva dirección de correo.',
  'Your phone number has been changed': 'Su teléfono ha sido modificado',
  'After you submit we will send a verification link to this new email address. The change will only be effective after you have verified this new email address.': 'recibirás un link de confirmación en el email abajo. El cambio solo tendrá efecto después que sigas el link.',
  'Min 8 characters, one uppercase, one lowercase and one number': 'Minimo 8 caracteres, por lo menos una mayuscula y por lo menos un número',
  'The token you are trying to use is not valid': 'El token que esta tratando de usar no es valido',
  'Access': 'Acceso',
  'There are not access codes available for the selected building': 'No hay codigos de acceso disponibles para el edificio seleccionado',
  'Company role': 'Cargo',
  'Company': 'Compañia',
  'Accept': 'Aceptar',
  'Terms and Conditions': 'Leí y acepto los términos y condiciones',
  'Privacy policy': 'Leí y acepto la política de privacidad',
  'There was changes in our Terms of service and Privacy Policy. Please review them and make sure you accept them before you continue using our application.': 'Los terminos y condiciones y la politica de privacidad fueron actualizados',
  'Changes in Terms of service and Privacy Policy': 'Cambios en condiciones de servicio y politica de privacidad',
  'Delete my account': 'Eliminar mi cuenta',
  'Your account has been deleted.': 'Su cuenta fue eliminada',
  'Deleting your account is irreversible. All your data will be lost, including your personal information, invitations you sent and received, associations to buildings and secretaries.': 'Eliminar su cuenta es irreversible. Toda su información se perdera, incluyendo información personal, invitaciones enviadas y recibidas, asociaciones a edificios y secretarias.',
  'Input your password to delete the account': 'Ingrese su contraseña para eliminar la cuenta',
  'I understand. Delete my account': 'Entiendo. Eliminar mi cuenta',
  'Invitations': 'Invitaciones',
  'Invitation': 'Invitacion',
  'The access code is being generated and will be visible here when the invitation date is due': 'El código de acceso está siendo generador y estará visible cuando la fecha de la invitación corresponda',
  'Host\'s email': 'Email del anfitrion',
  'Host\'s cellphone': 'Celular del anfitrion',
  'View invitation full detail': 'Ver detalle de la invitación completo',
  'The access code will be visible here once the invitation is confirmed': 'El código de acceso estará visible una vez la invitación sea aceptada',
  'Download the Podesubir app here': 'Descarga la aplicacion de Podesubir aqui',
  'My Badge': 'mi credencial',
  'Visitor': 'Visitante',
  'Meetings': 'reuniones',
  'New meeting': 'nueva reunion',
  'Other guests': 'Otros invitados',
  'ACTIVE': 'Esperando confirmación',
  'Invited': 'Invitado',
  'Multiple emails/cellphones separated by comma': 'Multiples emails/teléfonos separados por coma',
  'Guest\'s contact': 'Contacto del invitado',
  'Create meeting': 'Nueva reunion',
  'Guests': 'Invitados',
  'The meeting has been modified': 'La reunion fue modificada',
  'Remove guest from the meeting?': '¿Remover invitado de la reunion?',
  'Remove guest from the meeting': 'Remover invitado de la reunion',
  'Cancel meeting?': 'Al cancelar esta invitación, se le avisará al (a los) convidado(s). Estás seguro de que quieres cancelarla?',
  'The meeting has been cancelled': 'La reunion fue cancelada',
  'Add guests to the meeting': 'Agregar invitado a la reunion',
  'Add': 'Agregar',
  'For security reasons we encourage you to change your password.': 'Por motivos de seguridad le recomendamos cambiar su contraseña',
  'None': 'Ninguna',
  'Floor': 'Piso',
  'Room': 'Oficina/Sala',
  'Main company': 'Compañia raiz',
  'Allow access before meeting starts (minutes)': 'Permitir antes de comenzar la reunion (minutos)',
  'Information was sent on how to restore your password': 'Se envio un correo con información para recuperar tu contraseña',
  'Meetings & Invitations': 'reuniones & invitaciones',
  'There are no more records': 'No hay mas registros',
  'You were invited': 'te invitaron',
  'Invited by': 'Invitado por',
  'your best welcome': 'tu mejor recepción',
  'Sign up': 'Registrar',
  'Meeting date': 'Horario',
  'terms': 'términos y condiciones',
  'privacypolicy': 'política de privacidad',
  'New email': 'Nuevo email',
  'New cellphone number': 'Nuevo número de celular',
  'Attention': 'Atención',
  'Date from': 'Fecha desde',
  'Date to': 'Fecha hasta',
  'Group data by': 'Agrupar datos por',
  'Month': 'Mes',
  'Week': 'Semana',
  'Day': 'Dia',
  'Hour': 'Hora',
  'Show': 'Mostrar',
  'Visitors by building': 'Visitantes por edificio',
  'My stats': 'Mis reportes',
  'Visitors': 'Visitantes',
  'Visitors stay': 'Estadia de visitantes',
  'week': 'semana',
  'of': 'de',
  'Stats': 'Reportes',
  'Stats: stay': 'Reportes: estadia',
  'Average duration (minutes)': 'Duración promedio (minutos)',
  'Average visits duration': 'Duración promedio de visita',
  'Stats: visitors': 'Reportes: visitantes',
  'Stay': 'Duración estadia',
  'You need to be building manager to see reports': 'Se necesita rol de encargado de edificio para ver reportes',
  'Read QR code': 'Leer código QR',
  'to grant or deny access to the guest': 'para permitir o negar el acceso del invitado',
  'Search invitation': 'Buscar invitación',
  'Go back': 'Volver',
  'Access code': 'Código de acceso',
  'Search': 'Buscar',
  'Complete at least one of the fields': 'Completá al menos uno de los campos',
  'There are no invitations that match the search': 'No se encontraron invitaciones con los parametros especificados',
  'Search results': 'Resultados de busqueda',
  'Document': 'Documento',
  'passport': 'pasaporte',
  'Cpf': 'Cpf',
  'Access granted': 'Acceso aprobado',
  'Access granted at': 'Acceso aprobado el',
  'Exit approved at': 'Salida aprobada el',
  'Access denied': 'Acceso negado',
  'Access denied at': 'Acceso negado el',
  'Approve access?': 'Aprobar acceso?',
  'Reason for denial': 'Motivo del rechazo',
  'Deny access': 'Negar acceso',
  'Approve exit?': 'Aprobar salida?',
  'Update system clock': 'Reiniciar lector',
  'There are no buildings configured to use this functionality': 'No hay edificios configurados para usar esta funcionalidad',
  'Employees': 'Funcionarios',
  'Search for employees': 'Buscar funcionarios',
  'to search for employees': 'para buscar funcionarios',
  'Search employee': 'Buscar funcionario',
  'Search by': 'Buscar por campo',
  'RG/RNE/Hab': 'RG/RNE/Hab',
  'Search value': 'Valor',
  'No employees could be found': 'No se encontraron funcionarios',
  'Your clock seems to be off by {{minutes}} minutes': 'Atencion: su reloj parece estar fuera de hora por {{minutes}} minutos',
  'Authorize exit': 'Autorizar salida',
  'Authorize entry': 'Autorizar entrada',
  'The employee\'s entry was authorized successfully': 'La entrada del funcionario fue autorizada con exito',
  'Authorize employee entry?': '¿Autorizar entrada del funcionario?',
  'The employee\'s exit was authorized successfully': 'La salida del funcionario fue autorizada con exito',
  'Authorize employee exit?': '¿Autorizar salida del funcionario?',
};
