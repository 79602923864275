export default {
  'Email': 'email',
  'Password': 'senha',
  'Login': 'acessar',
  'You don\'t have an account?': 'não tem uma conta?',
  'Register here': 'abrir nova conta',
  'Role': 'função',
  'Name': 'nome',
  'Save': 'salvar',
  'Surname': 'sobrenome',
  'Confirm password': 'confirme a senha',
  'Register': 'inscrição',
  'Already have an account?': 'já tenho uma conta',
  'sign in': 'faça login',
  'My profile': 'meu perfil',
  'Logout': 'sair',
  'Change password': 'mudar senha',
  'Your profile picture was updated': 'sua imagem foi atualizada',
  'Your profile information was updated': 'sua informação foi atualizada',
  'New password': 'Nova senha',
  'Confirm new password': 'Confirme a senha',
  'Your password has been changed': 'Sua senha foi alterada',
  'Cancel': 'Cancelar',
  'Update information': 'Atualizar informação',
  'Upload new picture': 'Nova imagem',
  'Pending invitations for today': 'Invitações pendentes para hoje',
  'There are no pending invitations for today': 'Não há invitações pendentes para hoje',
  'My buildings': 'meus prédios',
  'My invitations': 'minhas invitações',
  'No buildings were found': 'Não há prédios encontrados',
  'Home': 'Inicio',
  'Building detail': 'Detalhes do prédio',
  'Address': 'Endereço',
  'Manager': 'Gerente',
  'My user roles in this building': 'Minhas funções de usuário neste prédio',
  'BUILDING_MANAGER': 'Gerenciador de prédios',
  'BUILDING_MANAGER.description': 'Permissão para gerenciar as informações e usuários do prédio, empresas, acessar ao prédio e criar convites de acesso',
  'COMPANY_MANAGER': 'Gerente da empresa',
  'COMPANY_MANAGER.description': 'Permissão para gerenciar as informações e usuários da empresa, acessar ao prédio e criar convites de acesso',
  'EMPLOYEE': 'Pessoal do edifício',
  'EMPLOYEE.description': 'Permissão para acessar ao prédio',
  'HOST_EMPLOYEE': 'Pessoal do edifício com permissão de host',
  'HOST_EMPLOYEE.description': 'Permissão para acessar ao prédio e criar convites de acesso',
  'Edit': 'Editar',
  'Manage companies': 'Gerenciar empresas',
  'No records found': 'Sem registros encontrados',
  'My roles': 'Minhas funções',
  'Manage users': 'Gerenciar usuários',
  'Detail': 'Detalhe',
  'The building information has been updated': 'A informação do prédio foi atualizada',
  'Building\'s users': 'Usuários do edifício',
  'Go to detail': 'Ir ao detalhe',
  'Add user to building': 'Adicionar usuário ao edifício',
  'Add user': 'Adicionar usuário',
  'Remove user': 'Remover usuário',
  'Remove': 'Remover',
  'User email': 'Email do usuário',
  'The user has been added to the building': 'O usuário foi adicionado ao edifício',
  'The user has been removed from the building': 'O usuário foi removido do edifício',
  'Remove user from building?': 'Remover usuário do edifício?',
  'Remove user from company?': 'Remover usuário da empresa?',
  'Yes': 'Sim',
  'No': 'Não',
  'My companies': 'minhas empresas',
  'Companies': 'Empresas',
  'Edit company': 'Editar empresa',
  'Create company': 'Criar empresa',
  'Manager email': 'Email do gerente',
  'The company has been added to the building': 'A empresa foi adicionada ao prédio',
  'The company has been updated': 'A empresa foi atualizada',
  'The user has been added to the company': 'O usuário foi adicionado à empresa',
  'The user has been removed from the company': 'O usuário foi removido da empresa',
  'Add user to company': 'Adicionar usuário a empresa',
  'Company\'s users': 'Usuários da empresa',
  'Back to the building\'s companies': 'Voltar as empresas do prédio',
  'My user roles in this company': 'Minhas funções de usuário nesta empresa',
  'Company detail': 'Detalhes da empresa',
  'New invitation': 'Novo convite',
  'The invitation has been sent': 'O convite foi enviado',
  'Building': 'edifício',
  'Host': 'Anfitrião',
  'You do not have invite permissions in any building': 'Voce não tem permissões de convite em nenhum edifício',
  'Guest\'s email': 'Email do convidado',
  'Guest': 'Convidado',
  'Invitation date': 'Horário',
  'As myself': 'Eu',
  'Invitation subject/reason': 'Assunto',
  'Invitation duration': 'Duração do convite',
  'Modify meeting': 'Atualizar',
  '1 hour': '1 hora',
  '2 hours': '2 horas',
  '3 hours': '3 horas',
  '5 hours': '5 horas',
  '12 hours': '12 horas',
  'Notes': 'Comentários',
  'Allow access to the building for': 'Duração',
  'Create invitation': 'Criar convite',
  'Invitation detail': 'Detalhe do convite',
  'Date': 'Data',
  'hours': 'Horas',
  'Access allowed for': 'Acesso permitido para',
  'You are hosting': 'você convidou',
  'Confirmed': 'Confirmado',
  'Waiting confirmation': 'Aguardando confirmação',
  'Rejected': 'Rejeitado',
  'Cancelled': 'Cancelado',
  'Status': 'Status',
  'Subject/Reason': 'Assunto',
  'Sent to': 'Enviado a/para',
  'Sent date': 'Data de envio',
  'I forgot my password': 'Esqueci a minha senha',
  'Password recovery': 'Recuperação de senha',
  'Input your email and we will send you a link to restore your password': 'Digite seu email e nós lhe enviaremos um link para restaurar sua senha',
  'Back': 'Voltar',
  'Information was sent on how to restore your password': 'Foi enviada informação sobre como restaurar sua senha',
  'Request password recovery': 'Solicitar recuperação de senha',
  'Your password as been restored. You can now access with your new password': 'Sua senha foi mudada. Você já pode fazer o log in com a nova senha.',
  'Your email has been verified': 'Seu email foi verificado',
  'The email could not be verified': 'Não foi possível verificar o email',
  'Verify email': 'Verificar o email',
  'PAST_DUE': 'Vencido',
  'CANCELLED': 'Cancelado',
  'REJECTED': 'Recusado',
  'CONFIRMED': 'Aceitado',
  'PENDING': 'Aguardando confirmação',
  'Confirm': 'Confirmar',
  'Reject': 'Recusar',
  'The assistance to the invitation has been confirmed': 'A assistência ao convite foi confirmada',
  'Confirm assistance?': 'Confirmar assistência?',
  'The invitation has been cancelled': 'O convite foi cancelado',
  'Cancel invitation?': 'Ao rejeitar este convite o anfitrião será notificado. Tem certeza de que quer rejeitar o convite?',
  'The invitation has been rejected': 'O convite foi rejeitado',
  'Reject invitation?': 'Ao rejeitar este convite o anfitrião será notificado. Tem certeza de que quer rejeitar o convite?',
  'Required': 'Campo obrigatório',
  'Invalid email': 'Email inválido',
  'Password confirm does not match': 'Confirmação de senha incorreta',
  'Select a building': 'Selecione um edifício',
  'Cellphone': 'Celular',
  'Birthdate': 'Data de nascimento',
  'Habilitaçao': 'Habilitaçao',
  'RNE number': 'RNE',
  'RG': 'RG',
  'RNE': 'RNE',
  'Passport': 'Passaporte',
  'ID Type': 'documento de identificação',
  'Invalid ID number': 'Número de identificação inválido',
  'RG number': 'RG',
  'CPF number': 'CPF',
  'Invalid RG number': 'RF inválido',
  'Invalid CPF number': 'CPF inválido',
  'Secretary': 'Assistente',
  'My secretaries': 'meus assistentes',
  'New secretary': 'definir assistente',
  'The user has been added as a secretary': 'O usuário foi adicionado como assistente',
  'No results': 'Nenhum resultado encontrado',
  'Search and select an user': 'Pesquise e selecione um usuário',
  'The user has been removed from your secretaries': 'O usuário foi removido das suas assistentes',
  'Remove user from your secretaries?': 'Remover usuário das suas assistentes?',
  '1. Select the building': '1. Selecionar o prédio',
  '2. Search a user and select from the list': '2. Pesquise e selecione da lista',
  'Send invitation as yourself or acting as a secretary for another user': 'Enviar o convite como você mesmo ou atuando como secretário de outro usuário',
  'Building not found': 'Prédio não encontrado',
  'Invalid token': 'Token inválido',
  'Forbidden': 'Proibido',
  'There\'s no user registered with that email': 'Não há usuário cadastrado com esse email',
  'Email or password incorrect': 'Email ou senha incorretos',
  'The building manager can not be removed': 'O gerente do edifício não pode ser removido',
  'Company not found': 'Empresa não encontrada',
  'The company manager can not be removed': 'O gerente da empresa não pode ser removido',
  'You cannot invite in this building': 'Você não tem permissão de convidar neste edifício',
  'The user cannot invite in this building': 'O usuário não pode convidar neste edifício',
  'Missing file': 'Arquivo desaparecido',
  'You can not cancel a past invitation': 'Você não pode cancelar um convite passado',
  'The invitation can not be cancelled': 'O convite não pode ser cancelado',
  'You can not reject your own invitation': 'Você não pode rejeitar seu próprio convite',
  'You can not reject a past invitation': 'Você não pode rejeitar um convite passado',
  'The invitation can not be rejected': 'O convite não pode ser rejeitado',
  'You can not confirm your own invitation': 'Você não pode confirmar seu próprio convite',
  'You can not confirm a past invitation': 'Você não pode confirmar um convite passado',
  'The invitation can not be confirmed': 'O convite não pode ser confirmado',
  'Invitation not found': 'Convite não encontrado',
  'Can\'t create invitations on behalf user': 'Não é possível criar convites em nome de outro usuário',
  'The user can\'t create invitations in this building': 'O usuário não pode criar convites neste edifício',
  'You are not allowed to perform this action': 'Você não tem permissão para executar esta ação',
  'Multiple emails separated by comma': 'Vários emails separados por vírgula',
  'Sent invitations': 'Convites enviados',
  'Received invitations': 'convites',
  'Account configuration': 'configurações',
  'My information': 'meus dados',
  'Account': 'Configurações da conta',
  'My vehicle': 'meu veículo',
  'Your vehicle information was updated': 'as informações do seu veículo foram atualizadas',
  'Make': 'marca',
  'Model': 'modelo',
  'Color': 'cor',
  'License plate': 'placa veículo',
  'The documents were uploaded': 'Os documentos foram carregados',
  'My documents': 'Meus documentos',
  'ID Front': 'atualizar foto da frente da identidade',
  'ID Back': 'atualizar foto do verso da identidade',
  'Email or cellphone number': 'email ou número de celular',
  'If using a cellphone number, it must be valid and include both country and area code. Ex (5511943210091)': 'No caso de usar um número de telefone celular, ele deve ser válido e incluir o país e o código de área. Ex (5511943210091)',
  'Verify cellphone': 'Verificar celular',
  'Your cellphone has been verified': 'Seu celular foi verificado',
  'The cellphone could not be verified': 'O celular não pôde ser verificado',
  'A message was sent to your number with a verification code': 'Uma mensagem foi enviada para o seu número com um código de verificação',
  'Skip': 'Pular',
  'Cellphone number': 'Celular',
  'Only numbers. Including country and area code': 'Apenas números. Incluindo o código do país e da área',
  'Verify cellphone number': 'Verificar número do celular',
  'Your cellphone is already verified': 'Seu celular já está verificado Seu celular já está verificado',
  'Request verification code': 'Solicitar código de verificação',
  'Verification code': 'Código de verificação',
  'Verify': 'Verificar',
  'Invalid number': 'Número invalido',
  'Invalid email or cellphone': 'e-mail ou celular inválido',
  'Change email': 'mudar email',
  'Change phone': 'mudar celular',
  'We have sent an email to your new email address. The change will be efective once you verify this new email': 'Enviámos um email para o seu novo endereço de email. A alteração será efetiva assim que você confirmar este novo e-mail',
  'Your phone number has been changed': 'Seu número do celular foi alterado',
  'After you submit we will send a verification link to this new email address. The change will only be effective after you have verified this new email address.': 'enviaremos um link de verificação pare este novo endereço de email. A alteração só será efetiva depois que você verificar este novo endereço de e-mail.',
  'Min 8 characters, one uppercase, one lowercase and one number': 'Mínimo de 8 caracteres, pelo menos um maiúscula e pelo menos um número',
  'The token you are trying to use is not valid': 'O token que você está tentando usar não é válido',
  'Access': 'Acesso',
  'There are not access codes available for the selected building': 'Não há códigos de acesso disponíveis para o edifício selecionado',
  'Company role': 'Cargo',
  'Company': 'Empresa',
  'Accept': 'Accept',
  'Terms and Conditions': 'Li e aceito os termos e condições',
  'Privacy policy': 'Li e aceito a política de privacidade',
  'There was changes in our Terms of service and Privacy Policy. Please review them and make sure you accept them before you continue using our application.': 'os termos e condições e a politica de privacidade foram atualizados.',
  'Changes in Terms of service and Privacy Policy': 'Alterações nos Termos de serviço e na Política de Privacidade',
  'Delete my account': 'Deletar minha conta',
  'Your account has been deleted.': 'Sua conta foi deletada.',
  'Deleting your account is irreversible. All your data will be lost, including your personal information, invitations you sent and received, associations to buildings and secretaries.': 'Excluir sua conta é irreversível. Todos os seus dados serão perdidos, incluindo suas informações pessoais, convites que você enviou e recebeu, associações a edifícios e secretárias. ',
  'Input your password to delete the account': 'Digite sua senha para excluir a conta',
  'I understand. Delete my account': 'Compreendo. Deletar minha conta',
  'Invitations': 'Convites',
  'Invitation': 'Convite',
  'The access code is being generated and will be visible here when the invitation date is due': 'O código de acesso está sendo gerado e está visível ao fechar os convites da correspondência',
  'Host\'s email': 'Email do Anfitrião',
  'Host\'s cellphone': 'Celular do Anfitrião',
  'View invitation ful detail': 'Ver detalhe da convite completo',
  'The access code will be visible here once the invitation is confirmed': 'O código de acesso estará visível uma vez no convite do mar',
  'Download the Podesubir app here': 'Faça o download da app da Podesubir aqui',
  'My Badge': 'meu crachá',
  'Visitor': 'visitante',
  'Meetings': 'Reuniões',
  'New meeting': 'nova reunião',
  'Other guests': 'Outros convidados',
  'ACTIVE': 'Esperando confirmação',
  'Invited': 'Convidado',
  'Multiple emails/cellphones separated by comma': 'Vários e-mails / celulares separados por vírgula',
  'Guest\'s contact': 'Contato do convidado',
  'Create meeting': 'Criar reunião',
  'Guests': 'Convidados',
  'The meeting has been modified': 'A reunião foi modificada',
  'Remove guest from the meeting?': 'Remover convidado da reunião?',
  'Remove guest from the meeting': 'Remover convidado da reunião',
  'Cancel meeting?': 'Ao cancelar este convite, o(s) convidado(s) serão notificados. Tem certeza de que quer cancelar?',
  'The meeting has been cancelled': 'A reunião foi cancelada',
  'Add guests to the meeting': 'Adicionar convidados à reunião',
  'Add': 'Adicionar',
  'For security reasons we encourage you to change your password.': 'Por motivos de segurança, recomendamos que você altere sua senha.',
  'None': 'Nenhum',
  'Floor': 'Piso',
  'Room': 'Sala/Escritório',
  'Main company': 'Empresa Principal',
  'Allow access before meeting starts (minutes)': 'Permitir acesso antes do início da reunião (minutos)',
  'Meetings & Invitations': 'reuniões e convites',
  'There are no more records': 'Não há mais registros',
  'You were invited': 'você foi convidado',
  'Invited by': 'Convidado por',
  'your best welcome': 'sua melhor recepção',
  'Sign up': 'Inscrever',
  'Meeting date': 'Horário',
  'terms': 'termos e condições',
  'privacypolicy': 'política de privacidade',
  'New email': 'novo email',
  'New cellphone number': 'Novo numero de celular',
  'Attention': 'Atenção',
  'Date from': 'Data de',
  'Date to': 'Data até',
  'Group data by': 'Agrupar dados por',
  'Month': 'Mês',
  'Week': 'Semana',
  'Day': 'Dia',
  'Hour': 'Hora',
  'Show': 'Mostrar',
  'Visitors by building': 'Visitantes construindo',
  'My stats': 'Meus relatórios',
  'Visitors': 'Visitantes',
  'Visitors stay': 'Os visitantes ficam',
  'week': 'semana',
  'of': 'do',
  'Stats': 'Relatórios',
  'Stats: stay': 'Relatórios: fique',
  'Average duration (minutes)': 'Duração média (minutos)',
  'Average visits duration': 'Duração média da visita',
  'Stats: visitors': 'Relatórios: visitantes',
  'Stay': 'Duração da estadia',
  'You need to be building manager to see reports': 'Função de gerente de prédio necessária para visualizar relatórios',
  'Read QR code': 'Ler código QR',
  'to grant or deny access to the guest': 'permitir ou negar acesso de convidado',
  'Search invitation': 'Procurar convite',
  'Go back': 'Retorna',
  'Access code': 'Código de acesso',
  'Search': 'Procurar',
  'Complete at least one of the fields': 'Preencha pelo menos um dos campos',
  'There are no invitations that match the search': 'Nenhum convite foi encontrado com os parâmetros especificados',
  'Search results': 'Procurar Resultados',
  'Document': 'Documento',
  'passport': 'passaporte',
  'Cpf': 'Cpf',
  'Access granted': 'Acesso aprovado',
  'Access granted at': 'Acesso aprovado no',
  'Exit approved at': 'Partida aprovada no',
  'Access denied': 'Acesso negado',
  'Access denied at': 'Acesso negado no',
  'Approve access?': 'Aprovar acesso?',
  'Reason for denial': 'Razão para rejeição',
  'Deny access': 'Negar acesso',
  'Approve exit?': 'Aprovar saída?',
  'Update system clock': 'Reiniciar leitor',
  'There are no buildings configured to use this functionality': 'Não há edifícios configurados para usar esta funcionalidade',
  'Employees': 'usuarios',
  'Search for employees': 'Procurar usuarios',
  'to search for employees': 'para procurar usuarios',
  'Search employee': 'Procurar funcionario',
  'Search by': 'Busca por campo',
  'RG/RNE/Hab': 'RG/RNE/Hab',
  'Search value': 'Valor',
  'No employees could be found': 'Nenhum usuário foi encontrado',
  'Your clock seems to be off by {{minutes}} minutes': 'Atenção: seu relógio parece estar atrasado por 10 minutos',
  'Authorize exit': 'Autorizar saída',
  'Authorize entry': 'Autorizar entrada',
  'The employee\'s entry was authorized successfully': 'A entrada do funcionário foi autorizada com sucesso',
  'Authorize employee entry?': 'Autorizar entrada de funcionário?',
  'The employee\'s exit was authorized successfully': 'A saída do funcionário foi autorizada com sucesso',
  'Authorize employee exit?': 'Autorizar saída de funcionário?',
  'grant_invitation_result_arrival_title': 'Anúncio de chegada obrigatório',
  'grant_invitation_result_arrival_message': 'Por favor, direcione o convidado para a recepção',
};
