import * as React from 'react';
import * as _ from 'lodash';
import * as qs from 'qs';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmDialog from '../../components/Shared/ConfirmDialog';
import Search from '../../components/Employees/search';
import Badge from '../../components/Dashboard/badge';
import * as GuardsApi from '../../api/guards';

class SearchContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      where: {
        type: 'id_number',
        value: '',
      },
      results: null,
    };
  }

  search = async ({ type, value }) => {
    this.setState({
      where: { type, value },
    });

    const { data } = await GuardsApi.searchEmployees({
      [type]: value,
    });

    this.setState({
      results: data,
    });
  }

  allowEntry = async ({ user_id, building_id }) => {
    const onConfirmClick = async () => {
      await GuardsApi.allowEmployeeEntry({ user_id, building_id });

      toast.success(this.props.t('The employee\'s entry was authorized successfully'));
      this.props.history.push('/app/employees');
    };

    confirmAlert({
      message: this.props.t('Authorize employee entry?'),
      customUI: (props) => <ConfirmDialog { ...props } {...{ onConfirmClick }}/>
    });
  };

  allowExit = async ({ user_id, building_id }) => {
    const onConfirmClick = async () => {
      await GuardsApi.allowEmployeeExit({ user_id, building_id });

      toast.success(this.props.t('The employee\'s exit was authorized successfully'));
      this.props.history.push('/app/employees');
    };

    confirmAlert({
      message: this.props.t('Authorize employee exit?'),
      customUI: (props) => <ConfirmDialog { ...props } {...{ onConfirmClick }}/>
    });
  };

  render() {
    return (
      <Search
        initialValues={this.state.where}
        onSubmit={this.search}
        results={this.state.results}
        allowEntry={this.allowEntry}
        allowExit={this.allowExit}
      />
    )
  }

  async componentDidMount() {
    const { type, value } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    if (type && value) {
      this.search({ type, value });
    }
  }
}

const mapStateToProps = (state) => ({
  selected: state.buildings.selected,
});

export default connect(mapStateToProps, null)(withTranslation()(SearchContainer));
