import { combineReducers } from 'redux';
import auth from './auth';
import ui from './ui';
import buildings from './buildings';

const rootReducer = combineReducers({
  auth,
  ui,
  buildings,
});

export default rootReducer;
