import * as Yup from 'yup';
import * as ValidateRgCpf from 'cpf-rg-validator';

const isValidCellphone = (value) => /^\+?(?:[0-9]●?){6,14}[0-9]$/.test(value);

export const isValidEmailOrCellphone = (value) => {
  if (!value) {
    return false;
  }

  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (re.test(value.toLowerCase())) {
    return true;
  }

  if (isValidCellphone(value)) {
    return true;
  }

  return false;
}

const strRequired = Yup.string().required('Required');
const email = Yup.string().email('Invalid email').required('Required');

export const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .test('username', 'Invalid email or cellphone number', (v) => isValidEmailOrCellphone(v))
    .required('Required'),
  password: strRequired,
});

export const RegisterSchema = Yup.object().shape({
  email,
  cellphone: Yup.string()
    .test('cellphone', 'Invalid number', (v) => isValidCellphone(v))
    .required('Required'),
  name: strRequired,
  surname: strRequired,
  password: Yup
    .string()
    .required('Min 8 characters, one uppercase, one lowercase and one number')
    .test('password', 'Min 8 characters, one uppercase, one lowercase and one number', (v) => {
      return /[A-Z]/.test(v) && /[a-z]/.test(v) && /\d/.test(v) && v.length >= 8;
    }),
  password_confirm: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password')], 'Password confirm does not match'),
  company: Yup.string().nullable(),
  id_type: Yup.string().required('Required'),
  id_number: Yup.string()
    .test('id_number', 'Invalid ID number', function(value) {
      const type = this.options.parent.id_type;

      if (type === 'passport') {
        return value && /^[A-Z]{1,3}\d{6,8}$/.test(value);
      }

      return !!value;
    })
    .required('Required'),
  tos: Yup.boolean().oneOf([true], 'Required'),
  privacy: Yup.boolean().oneOf([true], 'Required'),
});

export const RequestPwdRecoverySchema = Yup.object().shape({
  email,
});

export const ChangePwdSchema = Yup.object().shape({
  password: Yup
    .string()
    .required('Min 8 characters, one uppercase, one lowercase and one number')
    .test('password', 'Min 8 characters, one uppercase, one lowercase and one number', (v) => {
      return /[A-Z]/.test(v) && /[a-z]/.test(v) && /\d/.test(v) && v.length >= 8;
    }),
  password_confirm: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password')], 'Password confirm does not match'),
});

export const ChangePhoneSchema = Yup.object().shape({
  cellphone: Yup.string()
    .test('cellphone', 'Invalid number', (v) => isValidCellphone(v))
    .required('Required'),
});

export const ProfileSchema = Yup.object().shape({
  name: strRequired,
  surname: strRequired,
  cellphone: Yup.string().nullable(),
  birthdate: Yup.string().nullable(),
  id_type: Yup.string().required('Required'),
  id_number: Yup.string()
    .test('id_number', 'Invalid ID number', function(value) {
      const type = this.options.parent.id_type;

      if (type === 'passport') {
        return value && /^[A-Z]{1,3}\d{6,8}$/.test(value);
      }

      return !!value;
    })
    .required('Required'),
  cpf: Yup.string()
    .test('cpf', 'Invalid CPF number', value => !value || ValidateRgCpf.cpf(value))
    .nullable(),
  company: Yup.string().nullable(),
  company_role: Yup.string().nullable(),
});

export const AddUserSchema = Yup.object().shape({
  email,
  role: strRequired,
});

export const AddCompanySchema = Yup.object().shape({
  name: strRequired,
  floor: strRequired,
  room: Yup.string(),
  access_before_minutes: Yup.number('Number'),
  manager_email: email,
});

export const EditCompanySchema = Yup.object().shape({
  name: strRequired,
  floor: strRequired,
  room: Yup.string(),
  access_before_minutes: Yup.number('Number'),
  manager_email: Yup.string().email('Invalid email'),
});

export const CreateInvitationSchema = Yup.object().shape({
  guest_contact: Yup.array().of(
    Yup.string()
      .test('guest_contact', 'Invalid email or cellphone number', (v) => isValidEmailOrCellphone(v))
      .required('Required'),
  ).required('Required').min(1),
  subject: strRequired,
  building_id: Yup.number().required('Select a building'),
});

export const UpdateMeetingSchema = Yup.object().shape({
  date_from: Yup.string('Required'),
  date_to: Yup.string('Required'),
  subject: strRequired,
});

export const AddInvitationSchema = Yup.object().shape({
  guest_contact: Yup.array().of(
    Yup.string()
      .test('guest_contact', 'Invalid email or cellphone number', (v) => isValidEmailOrCellphone(v))
      .required('Required'),
  ).required('Required').min(1),
});

export const AddSecretarySchema = Yup.object().shape({
  building_id: Yup.number().required('Select a building'),
  username: Yup.string()
    .test('username', 'Invalid email or cellphone number', (v) => isValidEmailOrCellphone(v))
    .required('Required'),
});

export const CarSchema = Yup.object().shape({
  make: Yup.string().nullable(),
  model: Yup.string().nullable(),
  color: Yup.string().nullable(),
  license: Yup.string().nullable(),
});

export const VerifyCellphoneSchema = Yup.object().shape({
  code: Yup.string().required('Required'),
});

export const TosSchema = Yup.object().shape({
  tos: Yup.boolean().oneOf([true], 'Required'),
  privacy: Yup.boolean().oneOf([true], 'Required'),
});

export const DeleteAccountSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
});

export const StatsVisitorsSchema = Yup.object().shape({
  building_id: strRequired,
  from: Yup.date().required('Required'),
  to: Yup.date().min(Yup.ref('from')).required('Required'),
  group: strRequired,
});