import * as React from 'react';
import * as _ from 'lodash';
import * as qs from 'qs';
import { connect } from 'react-redux';
import Search from '../../components/Dashboard/search';
import Badge from '../../components/Dashboard/badge';
import * as GuardsApi from '../../api/guards';

class SearchContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      where: {
        type: 'id_number',
        value: '',
      },
      results: null,
    };
  }

  search = async ({ type, value }) => {
    this.setState({
      where: { type, value },
    });

    const { data } = await GuardsApi.search({
      [type]: value,
    });

    this.setState({
      results: data,
    });
  }

  setActiveInvitation = (id) => {
    if (!id) {
      return this.setState({ activeInvitation: null });
    }

    const inv = this.state.results.find((i) => i.id === id);

    this.setState({ activeInvitation: inv });
  }

  patchState = (inv) => {
    this.setState({
      activeInvitation: inv,
      results: _.orderBy(this.state.results.filter((i) => i.id !== inv.id).concat([inv]), (i) => i.id),
    });
  }

  allowEntry = async (id) => {
    const { data } = await GuardsApi.allowEntry(id);

    this.patchState(data);
  }

  allowExit = async (id) => {
    const { data } = await GuardsApi.allowExit(id);

    this.patchState(data);
  }

  denyEntry = async (id, values) => {
    const { data } = await GuardsApi.denyEntry(id, values);

    this.patchState(data);
  }

  render() {
    if (this.state.activeInvitation) {
      return (
        <Badge
          invitation={this.state.activeInvitation}
          setActiveInvitation={this.setActiveInvitation}
          allowEntry={this.allowEntry}
          denyEntry={this.denyEntry}
          allowExit={this.allowExit}
          currentBuilding={this.props.selected}
        />
      );
    }

    return (
      <Search
        initialValues={this.state.where}
        onSubmit={this.search}
        results={this.state.results}
        setActiveInvitation={this.setActiveInvitation}
      />
    )
  }

  async componentDidMount() {
    const { type, value } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    if (type && value) {
      this.search({ type, value });
    }
  }
}

const mapStateToProps = (state) => ({
  selected: state.buildings.selected,
});

export default connect(mapStateToProps, null)(SearchContainer);
