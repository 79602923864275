import * as React from 'react';
import { connect } from 'react-redux';
import UpdateClockComponent from '../../components/Dashboard/update-clock';
import * as GuardsApi from '../../api/guards';

const REACT_APP_SITE_URL = process.env.REACT_APP_SITE_URL;

class UpdateClock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      buildings: [],
    };
  }

  render() {
    return (
      <UpdateClockComponent
        loading={this.state.loading}
        buildings={this.state.buildings}
      />
    )
  }

  async componentDidMount() {
    const { data } = await GuardsApi.buildings();

    if (!data.length) {
      return window.location.replace(REACT_APP_SITE_URL);
    }

    this.setState({
      buildings: data.filter((d) => d.code_type === 'DYNAMIC_TOTP_ENCRYPTED' && d.totp && d.totp.clock_encryption),
      loading: false,
    });
  }
}

export default connect(null, null)(UpdateClock);
