import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Select } from '../Shared/BsInput';
import CameraStarted from './camera-started';

const styles = makeStyles((theme) => ({
  qrplaceholder: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),

    '& img': {
      border: '4px solid #333',
      width: '50%',
    }
  },
  buttons: {
    marginTop: theme.spacing(2),

    '& button': {
      marginTop: theme.spacing(1),
    },

    '& a': {
      marginTop: theme.spacing(1),
    },
  },
}));

export default ({
  buildings,
  loading,
  currentBuilding,
  changeBuilding,
  onQrRead,
}) => {
  const { t } = useTranslation();
  const classes = styles();
  const [cameraStarted, setCameraStarted] = React.useState(false);
  const startCamera = () => setCameraStarted(true);
  const stopCamera = () => setCameraStarted(false);
  const stored = localStorage.getItem('selected-device-facing-mode') || 'rear';

  if (loading) {
    return null;
  }

  if (cameraStarted) {
    return (
      <CameraStarted
        stopCamera={stopCamera}
        onQrRead={onQrRead}
        startCamera={startCamera}
        initialFacingMode={stored}
      />
    );
  }

  return (
    <>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} sm={9} md={6} lg={4}>
          {
            buildings && currentBuilding && buildings.length > 1 &&
            <Select
              name="building"
              value={currentBuilding.id}
              errors={{}}
              options={
                buildings.map((b) => ({
                  value: b.id,
                  label: b.name,
                }))
              }
              onChange={(e) => {
                const { value } = e.target;

                return changeBuilding(value);
              }}
            />
          }
          <div className={classes.qrplaceholder}>
            <img src="/qr-placeholder.png" onClick={startCamera} />
          </div>
          <Typography align="center" variant="h6" onClick={startCamera}>
            {t('Read QR code')}
          </Typography>
          <Typography align="center">
            {t('to grant or deny access to the guest')}
          </Typography>
          <div className={classes.buttons}>
            <Button
              type="button"
              onClick={startCamera}
              variant="outlined"
              fullWidth
            >
              {t('Read QR code')}
            </Button>
            <Button
              type="button"
              onClick={startCamera}
              variant="outlined"
              fullWidth
              component={Link}
              to="/app/search"
            >
              {t('Search invitation')}
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
