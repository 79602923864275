import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Formik } from 'formik';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from "@material-ui/lab/AlertTitle"
import { Input } from '../Shared/BsInput';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundImage: 'url("/badge-background.png")',
    backgroundPosition: 'top left',
    backgroundSize: '100% auto',
    backgroundRepeat: 'no-repeat',
    padding: '20px 20px',
    minHeight: '80vh',
    marginBottom: '15%',
  },
  logo: {
    '& img': {
      maxWidth: '100px',
    },
    marginBottom: '15%',

    [theme.breakpoints.down('sm')]: {
      marginBottom: '30%',
    },

    [theme.breakpoints.down('xs')]: {
      marginBottom: '15%',
    },
  },
  profilePictureContainer: {
    textAlign: 'center',
    position: 'relative',
    margin: '0 auto',
  },
  profilePicture: {
    borderRadius: '50%',
    width: '15vw',
    height: '15vw',
    margin: 'auto',
    border: '10px solid #fff',
    backgroundPosition: 'center',
    backgroundSize: 'cover',

    [theme.breakpoints.down('md')]: {
      width: '20vw',
      height: '20vw',
    },

    [theme.breakpoints.down('sm')]: {
      width: '30vw',
      height: '30vw',
    },

    [theme.breakpoints.down('xs')]: {
      width: '45vw',
      height: '45vw',
    },
  },
  textGreen: {
    color: '#07bc0c',
  },
  borderGreen: {
    borderColor: '#07bc0c',
  },
  textRed: {
    color: '#e74c3c',
  },
  borderRed: {
    borderColor: '#e74c3c',
  },
  information: {
    '& h5': {
      fontWeight: 'bold',
    }
  },
  header: {
    textTransform: 'uppercase',
    color: '#fff',
  },
  content: {
    marginTop: '10px',
  },
  descriptionField: {
    fontWeight: 'bold',
    textTransform: 'lowercase',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
  },
  descriptionValue: {
    lineHeight: '1rem',

    [theme.breakpoints.up('sm')]: {
      lineHeight: '1.5rem',
      fontSize: '1.2rem',
    },
  },
  breadcrumbs: {
    color: theme.palette.primary.main,
    marginBottom: '10px',

    '& li': {
      cursor: 'pointer',
    },

    '& li:last-child': {
      color: '#333',
    },

    '& a': {
      textDecoration: 'none !important',
      color: theme.palette.primary.main,
    }
  }
}));

const DenyForm = ({ onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        enter_renied_reason: ''
      }}
      enableReinitialize={true}
      validate={(values) => {
        const errors = {};

        if (!values.enter_renied_reason) {
          errors.enter_renied_reason = 'Required';
        }

        return errors;
      }}
      onSubmit={
        async (values, { setSubmitting, setErrors }) => {
          try {
            await onSubmit({ ...values });
            setSubmitting(false);
          } catch (err) {
            if (err.response && err.response.data.errors) {
              setErrors(err.response.data.errors);
            }

            setSubmitting(false);
          }
        }
      }
      render={({
        handleSubmit,
        handleChange,
        errors,
        values,
      }) => {
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <Input
                  type="text"
                  value={values.enter_renied_reason}
                  onChange={handleChange}
                  name="enter_renied_reason"
                  label={t('Reason for denial')}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  type="submit"
                  variant="outlined"
                  fullWidth
                  style={{ marginTop: '16px' }}
                >
                  {t('Deny access')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )
      }}
    />
  );
};

const ActionFooter = ({ invitation, allowEntry, denyEntry, allowExit, currentBuilding, setActiveInvitation }) => {
  const [denyForm, setDenyForm] = React.useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  if (invitation.Building.id !== parseInt(currentBuilding, 10)) {
    return (
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} className="text-center">
          <hr />
          <Alert elevation={6} variant="filled" severity="warning" icon={false} align="center">
            {t('The invitation\'s building does not match your selected building')}
          </Alert>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} className="text-center">
        <hr />
        {
          // access granted message
          invitation.enter_allowed_at &&
          <>
            <Typography align="center" className={classes.textGreen} variant="h6">
              {t('Access granted')}
            </Typography>
            <Typography align="center">
              {t('Access granted at')}: {moment(invitation.enter_allowed_at).format('DD-MM-YYYY HH:mm')}
            </Typography>
            {
              invitation.exit_allowed_at &&
              <Typography align="center">
                {t('Exit approved at')}: {moment(invitation.exit_allowed_at).format('DD-MM-YYYY HH:mm')}
              </Typography>
            }
          </>
        }
        {
          // access denied message
          invitation.enter_denied_at &&
          <div>
            <Typography align="center" className={classes.textRed} variant="h6">
              {t('Access denied')}
            </Typography>
            <Typography align="center" variant="body2">
              {invitation.enter_renied_reason}
            </Typography>
            <Typography align="center">
              {t('Access denied at')}: {moment(invitation.enter_denied_at).format('DD-MM-YYYY HH:mm')}
            </Typography>
          </div>
        }
        {
          // show allow/deny access button
          // the invitation is not allowed nor rejected
          !invitation.enter_allowed_at && !invitation.enter_denied_at && (
            <Typography component="div" align="center">
              {!invitation.announce_arrival ? ( 
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {t('Approve access?')}
                <IconButton style={{marginLeft: '10px'}} onClick={() => allowEntry(invitation.id)} className={classes.textGreen}>
                  <i className="fa fa-check"></i>
                </IconButton>
                <IconButton onClick={() => setDenyForm(true)} className={classes.textRed}>
                  <i className="fa fa-times"></i>
                </IconButton>
                </div>
              ): (
                <IconButton onClick={() => setActiveInvitation(null)} className={classes.textGreen}>
                  <i className="fa fa-check"></i>
                </IconButton>
              )}
            </Typography>
          )
        }
        {
          // show approve exit button
          invitation.enter_allowed_at && !invitation.exit_allowed_at && (
            <Typography component="div" align="center">
            {t('Approve exit?')} &nbsp;
            <IconButton onClick={() => allowExit(invitation.id)} className={classes.textGreen}>
              <i className="fa fa-check"></i>
            </IconButton>
          </Typography>
          )
        }
      </Grid>
      <Grid item xs={12} className="text-center">
        {
          !invitation.enter_allowed_at && !invitation.enter_denied_at && denyForm &&
          <DenyForm onSubmit={(values) => denyEntry(invitation.id, values)} />
        }
      </Grid>
    </Grid>
  );
};

export default ({
  invitation, setActiveInvitation,
  allowEntry, allowExit, denyEntry,
  currentBuilding,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = invitation.Guest;
  const url = user.profile_picture || '/profile-placeholder.png';

  return (
    <>
      <Breadcrumbs className={classes.breadcrumbs}>
        <Typography component={Link} to="/app">
          {t('Home')}
        </Typography>
        <Typography onClick={() => setActiveInvitation(null)}>
          {t('Search results')}
        </Typography>
        <Typography>
          {t('Invitation detail')}
        </Typography>
      </Breadcrumbs>
      <Paper className={classes.paper}>
        <div className={classes.logo}>
          <div>
            <Typography variant="h5" className={classes.header}>
              {t('Visitor')}
            </Typography>
          </div>
        </div>
        <div className={classes.profilePictureContainer}>
          <div
            className={clsx(classes.profilePicture, {
              [classes.borderGreen]: invitation.enter_allowed_at,
              [classes.borderRed]: invitation.enter_denied_at,
            })}
            style={{ backgroundImage: `url(${url})`}}
          >
          </div>
        </div>
        <div className={classes.information}>
          <Typography align="center" variant="h5" gutterBottom={false}>
            {user.name} {user.surname}
          </Typography>
          <Typography align="center" variant="body2">
            {user.company_role ? `${user.company_role}, ${user.company}` : user.company}
          </Typography>
        </div>
        <div className={classes.content}>

          <Grid container spacing={2} justify="center">
            <Grid item xs={3} md={2}>
              <Typography variant="body2" className={classes.descriptionField}>{t('Cellphone')}</Typography>
            </Grid>
            <Grid item xs={7} md={4}>
              <Typography align="right" className={classes.descriptionValue}>
                {user.cellphone}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} justify="center">
            <Grid item xs={3} md={2}>
              <Typography variant="body2" className={classes.descriptionField}>{t('Document')}</Typography>
            </Grid>
            <Grid item xs={7} md={4}>
              <Typography align="right" className={classes.descriptionValue}>
                {t(user.id_type)} {user.id_number}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} justify="center">
            <Grid item xs={3} md={2}>
              <Typography variant="body2" className={classes.descriptionField}>{t('Cpf')}</Typography>
            </Grid>
            <Grid item xs={7} md={4}>
              <Typography align="right" className={classes.descriptionValue}>
                {user.cpf}
              </Typography>
            </Grid>
          </Grid>

          <hr />
          <Grid container spacing={2} justify="center">
            <Grid item xs={3} md={2}>
              <Typography variant="body2" className={classes.descriptionField}>{t('Invited by')}</Typography>
            </Grid>
            <Grid item xs={7} md={4}>
              <Typography align="right" className={classes.descriptionValue}>
                {invitation.Host.name} {invitation.Host.surname}
                {
                  invitation.Host.company &&
                  <>
                    <br/>
                    {invitation.Host.company}
                  </>
                }
                {
                  invitation.Host.company_role &&
                  <>
                    <br />
                    {invitation.Host.company_role}
                  </>
                }
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} justify="center">
            <Grid item xs={3} md={2}>
              <Typography variant="body2" className={classes.descriptionField}>{t('Cellphone')}</Typography>
            </Grid>
            <Grid item xs={7} md={4}>
              <Typography align="right" className={classes.descriptionValue}>
                {invitation.Host.cellphone}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} justify="center">
            <Grid item xs={3} md={2}>
              <Typography variant="body2" className={classes.descriptionField}>{t('Meeting date')}</Typography>
            </Grid>
            <Grid item xs={7} md={4}>
              <Typography align="right" className={classes.descriptionValue}>
                {moment(invitation.date_from).format('ddd DD MMM  HH:mm')} - {moment(invitation.date_to).format('HH:mm')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} justify="center">
            <Grid item xs={3} md={2}>
              <Typography variant="body2" className={classes.descriptionField}>{t('Building')}</Typography>
            </Grid>
            <Grid item xs={7} md={4}>
              <Typography align="right" className={classes.descriptionValue}>
                {invitation.Building.name}
                <br />
                {invitation.Building.address} {invitation.Company && <>{invitation.Company.floor} - {invitation.Company.room}</>}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} justify="center">
            <Grid item xs={10} md={6}>
              {invitation.announce_arrival && (
                <Alert severity="warning" style={{marginTop: '15px'}}>
                  <AlertTitle>{t('grant_invitation_result_arrival_title')}</AlertTitle>
                  {t('grant_invitation_result_arrival_message')}
                </Alert>
              )}
            </Grid>
          </Grid>

          <ActionFooter
            {...{ invitation, denyEntry, allowExit, allowEntry, currentBuilding, setActiveInvitation }}
          />
        </div>
      </Paper>
    </>
  );
}
