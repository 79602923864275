import request from './index';

export function buildings() {
  return request({
    method: 'GET',
    path: '/api/guards/buildings',
  });
}

export function searchEmployees(params) {
  return request({
    params,
    method: 'GET',
    path: '/api/guards/employees/search',
  });
}

export function search(params) {
  return request({
    params,
    method: 'GET',
    path: '/api/guards/invitations/search',
  });
}

export function allowEntry(id) {
  return request({
    method: 'PUT',
    path: `/api/guards/invitations/${id}/allow-entry`,
  });
}

export function denyEntry(id, body) {
  return request({
    body,
    method: 'PUT',
    path: `/api/guards/invitations/${id}/deny-entry`,
  });
}

export function allowExit(id) {
  return request({
    method: 'PUT',
    path: `/api/guards/invitations/${id}/allow-exit`,
  });
}

export function allowEmployeeEntry(body) {
  return request({
    method: 'POST',
    body,
    path: '/api/guards/employees/allow-entry',
  });
};

export function allowEmployeeExit(body) {
  return request({
    method: 'POST',
    body,
    path: '/api/guards/employees/allow-exit',
  });
};
