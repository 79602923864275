import { API_REQUEST } from '../constants';

export function req() {
  return {
    payload: 1,
    type: API_REQUEST,
  };
}

export function res() {
  return {
    payload: -1,
    type: API_REQUEST,
  };
}
