import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Input, MaskedInput, Select } from '../Shared/BsInput';

export default ({
  values,
  errors,
  handleChange,
  handleSubmit,
  setFieldValue,
}) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} style={{ paddingTop: '16px' }}>
          <Select
            value={values.type}
            name="type"
            errors={errors}
            onChange={(e) => {
              setFieldValue('value', '');

              return handleChange(e);
            }}
            options={[
              { value: 'code', label: t('Access code') },
              { value: 'id_number', label: t('RG/RNE/Hab') },
              { value: 'cpf', label: t('Cpf') },
              { value: 'email', label: t('Email') },
              { value: 'cellphone', label: t('Cellphone') },
              { value: 'license', label: t('License plate') },
            ]}
            label={t('Search by')}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          {
            values.type === 'cpf' && (
              <MaskedInput
                type="text"
                placeholder="xxx.xxx.xxx-xx"
                value={values.value}
                onChange={handleChange}
                name="value"
                id="cpf-input"
                label={t('Search value')}
                errors={errors}
              />
            )
          }
          {
            values.type !== 'cpf' && (
              <Input
                type="text"
                value={values.value}
                onChange={handleChange}
                name="value"
                label={t('Search value')}
                errors={errors}
              />
            )
          }
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            type="submit"
            variant="outlined"
            fullWidth
            style={{ marginTop: '16px' }}
          >
            {t('Search')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};