import * as React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import Login from '../Login';
import ReqPwdRecovery from '../Login/request-pwd-recovery';

import Dashboard from '../Dashboard';
import Search from '../Dashboard/search';
import UpdateClock from '../Dashboard/update-clock';
import EmployeesIndex from '../Employees/index';
import EmployeesSearch from '../Employees/search';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    rest.loggedIn
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
);

const PublicOnlyRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    rest.loggedIn
      ? <Redirect to='/app' />
      : <Component {...props} />
  )} />
);

export default ({ loggedIn }) => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/app"
        component={Dashboard}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/update-clock"
        component={UpdateClock}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/search"
        component={Search}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/employees"
        component={EmployeesIndex}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/employees/search"
        component={EmployeesSearch}
        loggedIn={loggedIn}
      />
      <PublicOnlyRoute
        exact
        path="/login"
        component={Login}
        loggedIn={loggedIn}
      />
      <PublicOnlyRoute
        exact
        path="/request-password-recovery"
        component={ReqPwdRecovery}
        loggedIn={loggedIn}
      />

      <Redirect to={loggedIn ? '/app' : '/login'} />
    </Switch>
  );
}