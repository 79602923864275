import * as React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '10px',
    width: '100%',
    marginBottom: '5px',
    cursor: 'pointer',
  },
  accepted: {
    backgroundColor: 'rgba(223, 255, 223, 0.5)',
  },
  cancelled: {
    backgroundColor: 'rgba(255, 228, 228, 0.5)',
  },
  thumbContainer: {
    backgroundColor: theme.palette.primary.main,
    border: '1px solid #d0cbcb',
  },
  backgroundGreen: {
    backgroundColor: '#07bc0c',
  },
  backgroundRed: {
    backgroundColor: '#e74c3c',
  },
  thumbSingle: {
    width: '100%',
  },
  thumbMulti: {
    width: '50%',
  },
  plus: {
    width: '50%',
    display: 'inline-block',
    color: '#fff',
  },
  actions: {
    display: 'flex',
    lineHeight: '1rem',

    [theme.breakpoints.up('sm')]: {
      lineHeight: '1.5rem',
    },
  },
  webActions: {
    position: 'absolute',
    bottom: 0,
    width: '99%',
  },
  buttonsContainer: {
    flexGrow: 1,
    textAlign: 'right',
  },
  iconBtn: {
    padding: '0px 4px',
    color: 'rgb(255,0,0)',
    fontSize: '1.3rem',
    minWidth: 'inherit',
  },
  iconBtnSuccess: {
    color: theme.palette.secondary.main,
  },
  textBtnCancel: {
    backgroundColor: 'rgba(255, 0, 0, 0.05)',
    borderColor: 'rgba(255, 0, 0, 0.3)',
    color: 'rgba(255, 0, 0, 0.9)',
    borderWidth: '1px',
  },
  chipContainer: {
    flexGrow: 1,
  },
  chip: {
    fontSize: '0.6rem',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: '1px 5px',
    borderRadius: '16px',

    [theme.breakpoints.up('sm')]: {
      fontSize: '0.8rem',
      padding: '3px 10px',
    },
  },
  subject: {
    fontSize: '0.85rem',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.4rem',
    },
  },
  location: {
    fontSize: '0.75rem',
    lineHeight: '10px',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.2rem',
      lineHeight: '1.5rem',
    },
  },
}));

export default ({ invitation, setActiveInvitation }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const url = invitation.Guest.profile_picture || '/profile-placeholder.png';

  return (
    <Paper
      className={classes.paper}
      onClick={(e) => {
        e.stopPropagation();

        setActiveInvitation(invitation.id);
      }}
      elevation={3}
    >
      <Grid item container spacing={1}>
        <Grid item xs={3} md={2}>
          <div
            className={clsx(classes.thumbContainer, {
              [classes.backgroundGreen]: invitation.enter_allowed_at,
              [classes.backgroundRed]: invitation.enter_denied_at,
            })}
            >
            <img src={url} alt="guest-0" className={classes.thumbSingle} />
          </div>
        </Grid>
        <Grid item xs={9} md={10} style={{ position: 'relative' }}>
          <Typography className={classes.subject} component="div">
            {invitation.Guest.name} {invitation.Guest.surname} - {invitation.Guest.company}
          </Typography>
          <Typography className={classes.subject} component="div">
            <b>{invitation.subject}</b>
            &nbsp;
            {moment(invitation.date_from).format('HH:mm')} - {moment(invitation.date_to).format('HH:mm')}
          </Typography>
          <Typography className={classes.location} component="div">
            {invitation.Building.name} - {invitation.Building.address}
          </Typography>
          {
            invitation.Company &&
            <Typography className={classes.location} component="div">
              {invitation.Company.name} - {invitation.Company.floor} - {invitation.Company.room}
            </Typography>
          }
        </Grid>
      </Grid>
    </Paper>
  );
};
