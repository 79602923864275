import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import QrReader from './new-qr-reader';

const styles = makeStyles((theme) => ({
  buttons: {
    marginTop: theme.spacing(2),

    '& button': {
      marginTop: theme.spacing(1),
    },

    '& a': {
      marginTop: theme.spacing(1),
    },
  },
  cameraPreview: {
    textAlign: 'center',
  },
}));

export default ({ stopCamera, onQrRead, initialFacingMode }) => {
  const { t } = useTranslation();
  const classes = styles();
  const [facingMode, setFacingMode] = React.useState(initialFacingMode || 'rear');

  const changeFacingMode = () => {
    const c = facingMode === 'rear' ? 'front' : 'rear';

    localStorage.setItem('selected-device-facing-mode', c);
    setFacingMode(c);
  };

  console.log(facingMode, 'dev');

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={9} md={6} lg={4}>
        <div className={classes.cameraPreview}>
          <QrReader
            onScan={(e) => {
              if (e) {
                stopCamera();

                onQrRead(e);
              }
            }}
          />
          {/*<Box textAlign="center" px={4}>
            <IconButton
              variant="contained"
              color="primary"
              onClick={changeFacingMode}
            >
              <img src="/rotate-camera.png" width="40px" />
            </IconButton>
          </Box>*/}
        </div>


        <div className={classes.buttons}>
          <Button
            type="button"
            onClick={stopCamera}
            variant="outlined"
            fullWidth
          >
            {t('Cancel')}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}