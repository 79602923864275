import { API_REQUEST } from '../constants';

const defaultState = {
  loading: 0,
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case API_REQUEST: {
      return Object.assign({}, state, {
        loading: state.loading + (action.payload),
      });
    }
    default: {
      return state;
    }
  }
}
