export default {
  'Email': 'email',
  'Password': 'password',
  'Login': 'enter',
  'You don\'t have an account?': 'you don\'t have an account?',
  'Register here': 'sign up',
  'Role': 'role',
  'Name': 'name',
  'Save': 'save',
  'Surname': 'last name',
  'Confirm password': 'confirm password',
  'Register': 'subscribe',
  'Already have an account?': 'I already have an account',
  'sign in': 'sign in',
  'My profile': 'My profile',
  'Logout': 'log out',
  'Change password': 'Change password',
  'Your profile picture was updated': 'Your profile picture was updated',
  'Your profile information was updated': 'Your profile information was updated',
  'New password': 'New password',
  'Confirm new password': 'Confirm new password',
  'Your password has been changed': 'Your password has been changed',
  'Cancel': 'Cancel',
  'Update information': 'Update information',
  'Upload new picture': 'Upload new picture',
  'Pending invitations for today': 'Pending invitations for today',
  'There are no pending invitations for today': 'There are no pending invitations for today',
  'My buildings': 'my buildings',
  'My invitations': 'my invitations',
  'No buildings were found': 'No buildings were found',
  'Home': 'Home',
  'Building detail': 'Building detail',
  'Address': 'Address',
  'Manager': 'Manager',
  'My user roles in this building': 'My user roles in this building',
  'BUILDING_MANAGER': 'Building manager',
  'BUILDING_MANAGER.description': 'Permission to manage the building\'s information and users, companies, access the building and create access invitations',
  'COMPANY_MANAGER': 'Company manager',
  'COMPANY_MANAGER.description': 'Permission to manage the company\'s information and users, access the building and create access invitations',
  'EMPLOYEE': 'Building staff',
  'EMPLOYEE.description': 'Permission to access the building',
  'HOST_EMPLOYEE': 'Building staff with host permissions',
  'HOST_EMPLOYEE.description': 'Permission to access the building and create access invitations',
  'Edit': 'Edit',
  'Manage companies': 'Manage companies',
  'No records found': 'No records found',
  'My roles': 'My roles',
  'Manage users': 'Manage users',
  'Detail': 'Detail',
  'The building information has been updated': 'The building information has been updated',
  'Building\'s users': 'Building\'s users',
  'Go to detail': 'Go to detail',
  'Add user to building': 'Add user to building',
  'Add user': 'Add user',
  'Remove user': 'Remove user',
  'Remove': 'Remove',
  'User email': 'User email',
  'The user has been added to the building': 'The user has been added to the building',
  'The user has been removed from the building': 'The user has been removed from the building',
  'Remove user from building?': 'Remove user from building?',
  'Remove user from company?': 'Remove user from company?',
  'Yes': 'Yes',
  'No': 'No',
  'My companies': 'my companies',
  'Companies': 'Companies',
  'Edit company': 'Edit company',
  'Create company': 'Create company',
  'Manager email': 'Manager email',
  'The company has been added to the building': 'The company has been added to the building',
  'The company has been updated': 'The company has been updated',
  'The user has been added to the company': 'The user has been added to the company',
  'The user has been removed from the company': 'The user has been removed from the company',
  'Add user to company': 'Add user to company',
  'Company\'s users': 'Company\'s users',
  'Back to the building\'s companies': 'Back to the building\'s companies',
  'My user roles in this company': 'My user roles in this company',
  'Company detail': 'Company detail',
  'New invitation': 'New invitation',
  'The invitation has been sent': 'The invitation has been sent',
  'Building': 'building',
  'Host': 'Host',
  'You do not have invite permissions in any building': 'You do not have invite permissions in any building',
  'Guest\'s email': 'Guest\'s email',
  'Guest': 'Guest',
  'Invitation date': 'Time',
  'As myself': 'Me',
  'Invitation subject/reason': 'Asunto',
  'Invitation duration': 'Invitation duration',
  'Modify meeting': 'Update',
  '1 hour': '1 hour',
  '2 hours': '2 hours',
  '3 hours': '3 hours',
  '5 hours': '5 hours',
  '12 hours': '12 hours',
  'Notes': 'Comments',
  'Allow access to the building for': 'Duration',
  'Create invitation': 'Create invitation',
  'Invitation detail': 'Invitation detail',
  'Date': 'Date',
  'hours': 'hours',
  'Access allowed for': 'Access allowed for',
  'You are hosting': 'you invited',
  'Confirmed': 'Confirmed',
  'Waiting confirmation': 'Waiting confirmation',
  'Rejected': 'Rejected',
  'Cancelled': 'Cancelled',
  'Status': 'Status',
  'Subject/Reason': 'Subject/Reason',
  'Sent to': 'Sent to',
  'Sent date': 'Sent date',
  'I forgot my password': 'I forgot my password',
  'Password recovery': 'Password recovery',
  'Input your email and we will send you a link to restore your password': 'Input your email and we will send you a link to restore your password',
  'Back': 'Back',
  'Information was sent on how to restore your password': 'Information was sent on how to restore your password',
  'Request password recovery': 'Request password recovery',
  'Your password as been restored. You can now access with your new password': 'Your password as been restored. You can now login with your new password',
  'Your email has been verified': 'Your email has been verified',
  'The email could not be verified': 'The email could not be verified',
  'Verify email': 'Verify email',
  'PAST_DUE': 'Date past',
  'CANCELLED': 'Cancelled',
  'REJECTED': 'Declined',
  'CONFIRMED': 'Accepted',
  'PENDING': 'Pending',
  'Confirm': 'Confirm',
  'Reject': 'Reject',
  'The assistance to the invitation has been confirmed': 'The assistance to the invitation has been confirmed',
  'Confirm assistance?': 'Confirm assistance?',
  'The invitation has been cancelled': 'The invitation has been cancelled',
  'Cancel invitation?': 'The host will be notified that you are declining this invitation. Confirm that you are declining?',
  'The invitation has been rejected': 'The invitation has been rejected',
  'Reject invitation?': 'The host will be notified that you are declining this invitation. Confirm that you are declining?',
  'Required': 'Required',
  'Invalid email': 'Invalid email',
  'Password confirm does not match': 'Password confirm does not match',
  'Select a building': 'Select a building',
  'Cellphone': 'Mobile number',
  'Birthdate': 'Birthdate',
  'Habilitaçao': 'CNH',
  'RNE number': 'RNE number',
  'ID Type': 'government ID',
  'RG number': 'RG',
  'RG': 'RG',
  'RNE': 'RNE',
  'Passport': 'Passport',
  'CPF number': 'CPF',
  'Invalid ID number': 'Invalid ID number',
  'Invalid CPF number': 'Invalid CPF number',
  'Secretary': 'Secretary',
  'My secretaries': 'set delegates',
  'New secretary': 'new person',
  'The user has been added as a secretary': 'The user has been added as a secretary',
  'No results': 'No results',
  'Search and select an user': 'Search and select an user',
  'The user has been removed from your secretaries': 'The user has been removed from your secretaries',
  'Remove user from your secretaries?': 'Remove user from your secretaries?',
  '1. Select the building': '1. Select the building',
  '2. Search a user and select from the list': '2. Search a user and select from the list',
  'Send invitation as yourself or acting as a secretary for another user': 'Send invitation as yourself or acting as a secretary for another user',
  'Building not found': 'Building not found',
  'Invalid token': 'Invalid token',
  'Forbidden': 'Forbidden',
  'There\'s no user registered with that email': 'There\'s no user registered with that email',
  'Email or password incorrect': 'email or password incorrect',
  'The building manager can not be removed': 'The building manager can not be removed',
  'Company not found': 'Company not found',
  'The company manager can not be removed': 'The company manager can not be removed',
  'You cannot invite in this building': 'You cannot invite in this building',
  'The user cannot invite in this building': 'The user cannot invite in this building',
  'Missing file': 'Missing file',
  'You can not cancel a past invitation': 'You can not cancel a past invitation',
  'The invitation can not be cancelled': 'The invitation can not be cancelled',
  'You can not reject your own invitation': 'You can not reject your own invitation',
  'You can not reject a past invitation': 'You can not reject a past invitation',
  'The invitation can not be rejected': 'The invitation can not be rejected',
  'You can not confirm your own invitation': 'You can not confirm your own invitation',
  'You can not confirm a past invitation': 'You can not confirm a past invitation',
  'The invitation can not be confirmed': 'The invitation can not be confirmed',
  'Invitation not found': 'Invitation not found',
  'Can\'t create invitations on behalf user': 'Can\'t create invitations on behalf user',
  'The user can\'t create invitations in this building': 'The user can\'t create invitations in this building',
  'You are not allowed to perform this action': 'You are not allowed to perform this action',
  'Multiple emails separated by comma': 'Multiple emails separated by comma',
  'Sent invitations': 'Sent invitations',
  'Received invitations': 'Invites',
  'Account configuration': 'Settings',
  'My information': 'My information',
  'Account': 'Account settings',
  'My vehicle': 'My vehicle',
  'Your vehicle information was updated': 'Your vehicle information was updated',
  'Make': 'Brand',
  'Model': 'Model',
  'Color': 'Color',
  'License plate': 'License plate',
  'The documents were uploaded': 'The documents were uploaded',
  'My documents': 'My documents',
  'ID Front': 'update ID front picture',
  'ID Back': 'update ID back picture',
  'Email or cellphone number': 'email or cellphone number',
  'If using a cellphone number, it must be valid and include both country and area code. Ex (5511943210091)': 'If using a cellphone number, it must be valid and include both country and area code. Ex (5511943210091)',
  'Verify cellphone': 'Verify cellphone',
  'Your cellphone has been verified': 'Your cellphone has been verified',
  'The cellphone could not be verified': 'The cellphone could not be verified',
  'A message was sent to your number with a verification code': 'A message was sent to your number with a verification code',
  'Skip': 'Skip',
  'Cellphone number': 'Mobile number',
  'Only numbers. Including country and area code': 'Only numbers. Including country and area code',
  'Verify cellphone number': 'Verify cellphone number',
  'Your cellphone is already verified': 'Your cellphone is already verified',
  'Request verification code': 'Request verification code',
  'Verification code': 'Verification code',
  'Verify': 'Verify',
  'Invalid number': 'Invalid number',
  'Invalid email or cellphone': 'Invalid email or cellphone',
  'Change email': 'Change email',
  'Change phone': 'Change phone number',
  'We have sent an email to your new email address. The change will be efective once you verify this new email': 'We have sent an email to your new email address. The change will be efective once you verify this new email',
  'Your phone number has been changed': 'Your phone number has been changed',
  'After you submit we will send a verification link to this new email address. The change will only be effective after you have verified this new email address.': 'you will receive a verification link on the email below. The email change will only take effect after you follow that link.',
  'Min 8 characters, one uppercase, one lowercase and one number': 'Min 8 characters, at least one high cap letter and one numeral',
  'The token you are trying to use is not valid': 'The token you are trying to use is not valid',
  'Access': 'Access',
  'There are not access codes available for the selected building': 'There are not access codes available for the selected building',
  'Company role': 'Company role',
  'Company': 'Company',
  'Accept': 'Accept',
  'Terms and Conditions': 'I have read and agree to the terms and conditions',
  'Privacy policy': 'I have read and agree to the privacy policy',
  'There was changes in our Terms of service and Privacy Policy. Please review them and make sure you accept them before you continue using our application.': 'the terms and conditions and privacy policy were updated.',
  'Changes in Terms of service and Privacy Policy': 'Changes in Terms of service and Privacy Policy',
  'Delete my account': 'Delete my account',
  'Your account has been deleted.': 'Your account has been deleted.',
  'Deleting your account is irreversible. All your data will be lost, including your personal information, invitations you sent and received, associations to buildings and secretaries.': 'Deleting your account is irreversible. All your data will be lost, including your personal information, invitations you sent and received, associations to buildings and secretaries.',
  'Input your password to delete the account': 'Input your password to delete the account',
  'I understand. Delete my account': 'I understand. Delete my account',
  'Invitations': 'Invitations',
  'Invitation': 'Invitation',
  'The access code is being generated and will be visible here when the invitation date is due The access code is being generated and will be visible here when the invitation date is due': 'The access code is being generated and will be visible here when the invitation date is due The access code is being generated and will be visible here when the invitation date is due',
  'Host\'s email': 'Host\'s email',
  'Host\'s cellphone': 'Host\'s cellphone',
  'View invitation full detail': 'View invitation full',
  'The access code will be visible here once the invitation is confirmed': 'The access code will be visible here once the invitation is confirmed',
  'Download the Podesubir app here': 'Download the Podesubir app here',
  'My Badge': 'my badge',
  'Visitor': 'visitor',
  'Meetings': 'meetings',
  'New meeting': 'new meeting',
  'Other guests': 'other guests',
  'ACTIVE': 'waiting confirmation',
  'Invited': 'invited',
  'Multiple emails/cellphones separated by comma': 'multiple emails/cellphones separated by comma',
  'Guest\'s contact': 'guest\'s contact',
  'Create meeting': 'create meeting',
  'Guests': 'guests',
  'The meeting has been modified': 'The meeting has been modified',
  'Remove guest from the meeting?': 'Remove guest from the meeting?',
  'Remove guest from the meeting': 'Remove guest from the meeting',
  'Cancel meeting?': 'The guest(s) will be notified that you are cancelling this invitation. Confirm that you are canceling?',
  'The meeting has been cancelled': 'The meeting has been cancelled',
  'Add guests to the meeting': 'Add guests to the meeting',
  'Add': 'Add',
  'For security reasons we encourage you to change your password.': 'For security reasons we encourage you to change your password.',
  'None': 'None',
  'Floor': 'Floor',
  'Room': 'Room/Office',
  'Main company': 'Main company',
  'Allow access before meeting starts (minutes)': 'Allow access before meeting starts (minutes)',
  'Meetings & Invitations': 'meetings & invitations',
  'There are no more records': 'There are no more records',
  'You were invited': 'you are invited',
  'Invited by': 'Invited by',
  'your best welcome': 'your best welcome',
  'Sign up': 'Sign up',
  'Meeting date': 'Time',
  'terms': 'terms and conditions',
  'privacypolicy': 'privacy policy',
  'New email': 'New email',
  'New cellphone number': 'New cellphone number',
  'Attention': 'Attention',
  'Date from': 'Date from',
  'Date to': 'Date to',
  'Group data by': 'Group data by',
  'Month': 'Month',
  'Week': 'Week',
  'Day': 'Day',
  'Hour': 'Hour',
  'Show': 'Show',
  'Visitors by building': 'Visitors by building',
  'My stats': 'My reportes',
  'Visitors': 'Visitors',
  'Visitors stay': 'Visitor\'s stay',
  'week': 'week',
  'of': 'of',
  'Stats': 'Reportes',
  'Average duration (minutes)': 'Average duration (minutes)',
  'Average visits duration': 'Average visits duration',
  'Stay': 'Stay',
  'You need to be building manager to see reports': 'You need to be building manager to see reports',
  'Read QR code': 'Read QR code',
  'to grant or deny access to the guest': 'to grant or deny access to the guest',
  'Search invitation': 'Search invitation',
  'Go back': 'Go back',
  'Access code': 'Access code',
  'Search': 'Search',
  'Complete at least one of the fields': 'Complete at least one of the fields',
  'There are no invitations that match the search': 'No invitations where found with those params',
  'Search results': 'Search results',
  'Document': 'Document',
  'passport': 'passport',
  'Cpf': 'Cpf',
  'Access granted': 'Access granted',
  'Access granted at': 'Access granted at',
  'Exit approved at': 'Exit approved at',
  'Access denied': 'Access denied',
  'Access denied at': 'Access denied at',
  'Approve access?': 'Approve access?',
  'Reason for denial': 'Reason for denial',
  'Deny access': 'Deny access',
  'Approve exit?': 'Approve exit?',
  'Update system clock': 'Restart reader',
  'There are no buildings configured to use this functionality': 'There are no buildings configured to use this functionality',
  'Employees': 'Employees',
  'Search for employees': 'Search for employees',
  'to search for employees': 'to search for employees',
  'Search employee': 'Search employee',
  'Search by': 'Search by',
  'RG/RNE/Hab': 'RG/RNE/Hab',
  'Search value': 'Search value',
  'No employees could be found': 'No employees could be found',
  'Your clock seems to be off by {{minutes}} minutes': 'Warning: your clock seems to be off by {{minutes}} minutes',
  'Authorize exit': 'Authorize exit',
  'Authorize entry': 'Authorize entry',
  'The employee\'s entry was authorized successfully': 'The employee\'s entry was authorized successfully',
  'Authorize employee entry?': 'Authorize employee entry?',
  'The employee\'s exit was authorized successfully': 'The employee\'s exit was authorized successfully',
  'Authorize employee exit?': 'Authorize employee exit?',
  'grant_invitation_result_arrival_title': 'Arrival announcement required',
  'grant_invitation_result_arrival_message': 'Please direct the guest to the reception',
};