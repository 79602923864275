import { UPDATE_USER_DATA, TOS_ACCEPTED } from '../constants';

export function updateUserData(data) {
  return {
    data,
    type: UPDATE_USER_DATA,
  };
}

export function tosAccepted() {
  return {
    type: TOS_ACCEPTED,
  };
}
