import 'crypto';
import axios from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import QRCode from 'react-qr-code';
import moment from 'moment';
import { Select } from '../Shared/BsInput';
import { getUTCTime } from '../../api/index';

export default ({ loading, buildings }) => {
  const { t } = useTranslation();
  const [timeoffset, setTimeoffset] = React.useState(0);
  const [timestr, setTimestr] = React.useState(null);
  const [selected, setSelected] = React.useState(null);
  const [code, setCode] = React.useState(null);
  const [timer, setTimer] = React.useState(0);
  let timeout;

  React.useEffect(() => {
    getOnlineTime();
  }, []);

  const getOnlineTime = async () => {
    const { data } = await getUTCTime();

    const current = data.currentDateTime;

    const diff = moment().utc().diff(moment(current), 'minutes');

    setTimeoffset(diff);
  };

  const tic = () => {
    setTimer(timer + 1);
  };

  const getCorrectTime = () => {
    const now = moment().utc();
    const format = 'YYYY-MM-DD HH:mm:ss';

    if (timeoffset >= -1 && timeoffset <= 1) {
      return now.format(format);
    }

    if (timeoffset < 0) {
      return now.add(Math.abs(timeoffset), 'minutes').format(format);
    }

    return now.subtract(Math.abs(timeoffset), 'minutes').format(format);
  };

  const generate = () => {
    let strGenerate = `systemtime='${getCorrectTime()}'`;
    setTimestr(strGenerate);

    let content = `___VBAR_CONFIG_V1.1.0___{${strGenerate}}`;
    content = content.replace(/(^\s*)|(\s*$)/g, "");

    const contenthmacmd5 = window.CryptoJS.HmacMD5(content, selected.totp.clock_encryption);
    const contenthmacmd5base64 = window.CryptoJS.enc.Base64.stringify(contenthmacmd5);
    strGenerate = content + "--" + contenthmacmd5base64;

    return strGenerate;
  };

  React.useEffect(() => {
    if (!loading && buildings && buildings.length) {
      setSelected(buildings[0]);
    }
  }, [buildings]);

  React.useEffect(() => {
    if (selected) {
      timeout = setTimeout(() => tic(), 1000);
      setCode(generate());
    }
  }, [selected]);

  React.useEffect(() => {
    if (!loading) {
      timeout = setTimeout(() => tic(), 1000);
      setCode(generate());
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [timer]);

  if (loading) {
    return null;
  }

  if (!buildings.length) {
    return (
      <Alert severity="info" elevation={3}>
        {t('There are no buildings configured to use this functionality')}
      </Alert>
    );
  }

  if (!selected || !code) {
    return null;
  }

  return (
    <Box p={2} textAlign="center">
      <Typography variant="h4">
        {t('Update system clock')}
      </Typography>
      <Box>
        <Select
          name="building"
          label={t('Building')}
          disabled={buildings.length === 1}
          onChange={(e) => {
            const { value } = e.target;

            const building = buildings.find((b) => parseInt(value, 10) === b.id);

            setSelected(building);
          }}
          errors={{}}
          options={buildings.map((b) => ({ label: b.name, value: b.id }))}
          value={selected ? selected.id : null}
        />
      </Box>
      <Box py={2}>
        <QRCode value={code} size={180}/>
        <Typography variant="body2" align="center">
          {timestr}
        </Typography>
      </Box>
      {
        timeoffset !== 0 && (
          <Alert severity="info" elevation={3}>
            {t('Your clock seems to be off by {{minutes}} minutes', { minutes: Math.abs(timeoffset) })}
          </Alert>
        )
      }
    </Box>
  );
};
