import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';

export default ({ onScan }) => {
  return (
    <>
      <QrReader
        onResult={(result, error) => {
          if (!!result) {
            onScan(result?.text)
          }
        }}
        constraints={{
            facingMode: 'environment',
          }}
        style={{ width: '100%' }}
        scanDelay={0}
      />
    </>
  );
};