import * as React from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Button,
  Grid,
} from '@material-ui/core';
import { Input } from '../Shared/BsInput';
import { RequestPwdRecoverySchema } from '../../validation-schemas';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  t,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Input
        type="text"
        value={values.email}
        onChange={handleChange}
        name="email"
        label={t('Email')}
        errors={errors}
      />
      <Button
        className="mt-2"
        variant="outlined"
        color="default"
        type="submit"
        disabled={isSubmitting}
        fullWidth
      >
        {t('Request password recovery')}
      </Button>
    </form>
  );
};

export default ({ onSubmit, success }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Grid container direction="row" justify="center" spacing={3} style={{ marginBottom: '20%' }}>
        <Grid item xs={12} sm={8} md={4}>
          <Typography variant="h6" align="center">
            {t('Password recovery')}
          </Typography>
          {
            success &&
            <div>
              <div className="alert alert-success text-center">
                {t('Information was sent on how to restore your password')}
              </div>
            </div>
          }
          {
            !success &&
            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={RequestPwdRecoverySchema}
              enableReinitialize={true}
              onSubmit={
                async (values, { setSubmitting, setErrors }) => {
                  try {
                    await onSubmit(values);
                  } catch (err) {
                    if (err.response && err.response.data.error) {
                      setErrors({
                        email: err.response.data.error,
                      });
                    }

                    setSubmitting(false);
                  }
                }
              }
              render={p => <RenderForm {...p} {...{ t }}/>}
            />
          }
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
