import * as React from 'react';
import PwdRecoveryForm from '../../components/Login/request-pwd-recovery';
import * as AuthApi from '../../api/auth';

export default class PwdRecoveryContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      success: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    await AuthApi.requestPwdRestore(body);

    this.setState({ success: true });
  }

  render() {
    return (
      <PwdRecoveryForm
        success={this.state.success}
        onSubmit={this.onSubmit}
      />
    )
  }
}
