import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import {
  Typography,
  Button,
  AppBar,
  Toolbar,
  Grid,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Input, Password } from '../Shared/BsInput';
import { LoginSchema } from '../../validation-schemas';

const REACT_APP_SITE_URL = process.env.REACT_APP_SITE_URL;

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  t,
}) => {
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit} autoComplete="new-password">
      <input type="hidden" value="something" />
      <Input
        type="text"
        value={values.username}
        onChange={handleChange}
        name="username"
        label={t('Email or cellphone number')}
        errors={errors}
      />
      <Password
        value={values.password}
        onChange={handleChange}
        name="password"
        label={t('Password')}
        errors={errors}
      />
      <Typography align="right" className="my-2" variant="body2">
        <Link className={classes.link} to="/request-password-recovery">{t('I forgot my password')}</Link>
      </Typography>

      <Button
        variant="outlined"
        color="default"
        type="submit"
        disabled={isSubmitting}
        fullWidth
      >
        {t('Login')}
      </Button>
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  logo: {
    textAlign: 'center',
    marginTop: '15%',
    marginBottom: '30px',

    '& img': {
      maxWidth: '60%',
    },
  },
  download: {
    maxWidth: '25%',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '20%',
    },

    [theme.breakpoints.down('xs')]: {
      maxWidth: '35%',
    },
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: 'rgba(87,87,87,0.1)',
  },
  toolBar: {
    minHeight: '50px',
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    fontWeight: 'bold',

    '&:hover': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
  },
}));


export default ({ onSubmit }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container direction="row" justify="center" spacing={3}>
        <Grid item xs={12} sm={8} md={4}>
          <div className={classes.logo}>
            <img src="/logo-alt.png" alt="logo" />
            <Typography variant="h6">
              {t('guard\'s application')}
            </Typography>
          </div>
          <Formik
            initialValues={{
              username: '',
              password: '',
            }}
            validationSchema={LoginSchema}
            enableReinitialize={true}
            onSubmit={
              async (values, { setSubmitting, setErrors }) => {
                try {
                  await onSubmit(values);
                } catch (err) {
                  if (err.response && err.response.data.errors) {
                    setErrors(err.response.data.errors);
                  }

                  setSubmitting(false);
                }
              }
            }
            render={p => <RenderForm {...p} {...{ t }}/>}
          />
        </Grid>
      </Grid>
      <AppBar position="fixed" color="default" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Typography align="center" variant="body2" component="div" style={{ flex: 1 }}>
            {t('You don\'t have an account?')} <a href={REACT_APP_SITE_URL} className={classes.link}>{t('Register here')}</a>
          </Typography>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}