import axios from 'axios';
import request from './index';

export function me() {
  return request({
    method: 'GET',
    path: '/api/users',
  });
}

export function acceptTos(body) {
  return request({
    body,
    method: 'POST',
    path: '/api/users/accept-tos',
  });
}