import React from 'react';
import { connect } from 'react-redux';
import TosModalComponent from '../../components/Tos/modal';
import * as Api from '../../api/users';
import { tosAccepted } from '../../actions/users';

class TosModal extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    await Api.acceptTos(body);

    this.props.tosAccepted();
  }

  render() {
    return (
      <TosModalComponent onSubmit={this.onSubmit} />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  tosAccepted: () => dispatch(tosAccepted()),
});

export default connect(null, mapDispatchToProps)(TosModal);
