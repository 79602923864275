export const BUILDING_MANAGER = 'BUILDING_MANAGER';
export const COMPANY_MANAGER = 'COMPANY_MANAGER';
export const HOST_EMPLOYEE = 'HOST_EMPLOYEE';
export const EMPLOYEE = 'EMPLOYEE';
export const GUEST = 'GUEST';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const API_REQUEST = 'API_REQUEST';
export const TOS_ACCEPTED = 'TOS_ACCEPTED';
export const SET_BUILDINGS = 'SET_BUILDINGS';
export const SET_SELECTED_BUILDING = 'SET_SELECTED_BUILDING';
